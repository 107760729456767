/* Variables
   ========================================================================== */
/* Imports
========================================================================== */
/* ==========================================================================
   Common (Copied from Aria)
   ========================================================================== */
.t-Button {
  padding: 14px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px; }
  .t-Button--hot {
    font-weight: 700; }

.a-Button,
.t-Button,
.ui-button {
  border-radius: 4px;
  outline: none !important;
  border: 0;
  transition: .15s ease; }
  .a-Button .t-Button-label,
  .t-Button .t-Button-label,
  .ui-button .t-Button-label {
    vertical-align: top;
    display: inline-block; }

.t-Button--icon {
  text-align: center;
  min-width: 4rem; }
  .t-Button--icon.t-Button--noLabel {
    padding: 12px !important; }

.t-Button--iconLeft .t-Icon {
  margin-right: 6px; }

.t-Button--iconRight .t-Icon {
  margin-left: 6px; }

.a-Button:focus,
.apex-button-group input + label:focus,
.t-Button:not(.t-Button--simple):not(.t-Button--noUI):not(.t-Button--header):not(.t-Button--link):focus,
.t-Form-fieldContainer--radioButtonGroup .apex-item-group--rc input + label:focus,
.ui-button:focus {
  outline-color: #312D2A !important; }

.a-Button:before, .a-Button:after,
.apex-button-group input + label:before,
.apex-button-group input + label:after,
.t-Button:not(.t-Button--simple):not(.t-Button--noUI):not(.t-Button--header):not(.t-Button--link):before,
.t-Button:not(.t-Button--simple):not(.t-Button--noUI):not(.t-Button--header):not(.t-Button--link):after,
.t-Form-fieldContainer--radioButtonGroup .apex-item-group--rc input + label:before,
.t-Form-fieldContainer--radioButtonGroup .apex-item-group--rc input + label:after,
.ui-button:before,
.ui-button:after {
  content: none; }

.a-Button--hot,
.t-Button--hot:not(.t-Button--simple):not(.t-Button--noUI):not(.t-Button--header):not(.t-Button--link),
body .ui-button.ui-button--hot,
body .ui-state-default.ui-priority-primary {
  background-color: #377E55;
  color: #FCFBFA;
  box-shadow: none; }
  .a-Button--hot:focus,
  .t-Button--hot:not(.t-Button--simple):not(.t-Button--noUI):not(.t-Button--header):not(.t-Button--link):focus,
  body .ui-button.ui-button--hot:focus,
  body .ui-state-default.ui-priority-primary:focus {
    outline-color: #377E55 !important; }

.a-Button--hot:hover,
.a-Button--hot:not(:active):focus,
.t-Button--hot:not(.t-Button--simple):not(.t-Button--noUI):not(.t-Button--header):not(.t-Button--link):hover,
.t-Button--hot:not(.t-Button--simple):not(.t-Button--noUI):not(.t-Button--header):not(.t-Button--link):not(:active):focus,
body .ui-button.ui-button--hot:hover,
body .ui-button.ui-button--hot:not(:active):focus,
body .ui-state-default.ui-priority-primary:hover,
body .ui-state-default.ui-priority-primary:not(:active):focus {
  background-color: #31704C;
  color: #FCFBFA;
  box-shadow: none; }

.t-Button:not(.t-Button--link):not(.t-Button--header):not(.t-Button--link).is-active,
.t-Button:not(.t-Button--link):not(.t-Button--header):not(.t-Button--link):active {
  box-shadow: none !important; }

.a-Button--hot.is-active,
.a-Button--hot:active,
.t-Button--hot:not(.t-Button--simple):not(.t-Button--noUI):not(.t-Button--header):not(.t-Button--link).is-active,
.t-Button--hot:not(.t-Button--simple):not(.t-Button--noUI):not(.t-Button--header):not(.t-Button--link):active,
body .ui-button.ui-button--hot.is-active,
body .ui-button.ui-button--hot:active,
body .ui-state-default.ui-priority-primary.is-active,
body .ui-state-default.ui-priority-primary:active {
  background-color: #2B6242;
  color: #FCFBFA;
  box-shadow: none; }

.t-Button--link .t-Icon,
.t-Button--link.t-Button {
  color: #AE562C; }
  .t-Button--link .t-Icon:hover, .t-Button--link .t-Icon:active, .t-Button--link .t-Icon:focus,
  .t-Button--link.t-Button:hover,
  .t-Button--link.t-Button:active,
  .t-Button--link.t-Button:focus {
    color: #AE562C; }

.t-Button--hideShow {
  padding: 14px; }

.t-Button--small {
  padding: 10px 12px; }
  .t-Button--small.t-Button--icon {
    padding: 10px; }
  .t-Button--small .t-Icon {
    font-size: 16px;
    line-height: inherit; }

.t-Button--tiny {
  padding: 8px 12px;
  font-weight: 500; }
  .t-Button--tiny.t-Button--icon {
    padding: 10px; }
  .t-Button--tiny .t-Icon {
    line-height: inherit; }

.t-Button--large {
  padding: 20px 24px; }
  .t-Button--large.t-Button--icon {
    padding: 20px; }

.t-Button--rounded {
  border-radius: 64px; }

.a-Button--devToolbar {
  font-weight: 400;
  font-size: 12px; }

.t-Button--closeAlert {
  padding: 8px; }

/* Body
   ========================================================================== */
body {
  background-color: #ECE9E6;
  color: #1A1816;
  font-family: "Oracle Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

a {
  color: #AE562C; }

body.apex-no-scroll {
  width: 100% !important; }

body span[tabindex]:focus,
body li[tabindex]:focus,
body div[tabindex]:focus,
body button:focus,
body a:focus,
body .a-Button:focus,
body .t-Button:focus,
body .ui-button:focus {
  outline: dotted 2px !important;
  outline-offset: 2px !important;
  outline-width: 2px !important;
  outline-style: dotted !important; }

form#wwvFlowForm,
.t-Body-title {
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  body:not(.t-Dialog-page) form#wwvFlowForm, body:not(.t-Dialog-page)
  .t-Body-title {
    max-width: 1440px; }

form#wwvFlowForm {
  background-color: #FCFBFA; }

/* Body Content
       ========================================================================== */
.t-Body-content {
  min-height: calc(100vh - 64px); }
  @media only screen and (max-width: 480px) {
    .t-Body-content {
      min-height: calc(100vh - 48px); } }

/* Content Block
       ========================================================================== */
.t-ContentBlock--lightBG .t-ContentBlock-body {
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.075); }

div:not(.t-ContentBlock-body) > .container,
.t-Form-inputContainer {
  overflow: visible; }

/* Max Width Pages
       ========================================================================== */
.t-Header-branding,
.t-Body-title .t-HeroRegion-wrap,
.t-Body-title .t-BreadcrumbRegion,
.t-Body-title .t-ButtonRegion,
.t-Body-contentInner,
.t-Body-title .at-TabsRegion {
  margin: 0 auto;
  max-width: 1440px;
  padding-left: 36px;
  padding-right: 36px; }
  @media only screen and (max-width: 992px) {
    .t-Header-branding,
    .t-Body-title .t-HeroRegion-wrap,
    .t-Body-title .t-BreadcrumbRegion,
    .t-Body-title .t-ButtonRegion,
    .t-Body-contentInner,
    .t-Body-title .at-TabsRegion {
      padding-left: 16px;
      padding-right: 16px; } }

/* Header
   ========================================================================== */
.t-Header {
  background-color: #403C38;
  min-height: 64px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 320px;
  left: auto;
  right: auto;
  width: 100%; }

.t-PageBody--topBar .t-Header {
  box-shadow: none; }

.t-Header-branding {
  overflow: visible;
  background-color: transparent; }

.t-Header-controls {
  display: none; }

.t-Header-logo {
  margin-right: auto;
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: visible;
  line-height: 16px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 700; }
  .t-Header-logo img {
    max-height: 64px; }
    @media only screen and (max-width: 480px) {
      .t-Header-logo img {
        max-height: 48px; } }

.t-Header-logo-link {
  padding: 10px;
  margin: -10px;
  white-space: nowrap;
  border-radius: 4px; }
  .t-Header-logo-link:active:focus, .t-Header-logo-link:focus {
    box-shadow: none; }
  .t-Header-logo-link > span {
    display: flex;
    align-items: center; }
    .t-Header-logo-link > span img {
      position: relative;
      z-index: -1; }
    .t-Header-logo-link > span img + span {
      margin-left: 12px; }
    .t-Header-logo-link > span:before {
      content: '';
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI0M3NDYzNCIgZD0iTTI0LjYyOCAwQzMwLjkyMyAwIDM2IDUuMzc0IDM2IDEyLjAwMyAzNiAxOC42MzIgMzAuOTIzIDI0IDI0LjYyOCAyNGwtMTMuMjQtLjAwNkM1LjA5OSAyMy45OTQgMCAxOC42MzIgMCAxMi4wMDNTNS4wOTkgMCAxMS4zODkgMGgxMy4yMzl6bS0uMzAyIDQuMDI1SDExLjY4MmMtNC4wNyAwLTcuMzY5IDMuNTc0LTcuMzY5IDcuOTk3IDAgNC40MTggMy4zIDguMDAzIDcuMzcgOC4wMDNoMTIuNjQzYzQuMDgyIDAgNy4zNzctMy41ODUgNy4zNzctOC4wMDMgMC00LjQyMy0zLjI5NS03Ljk5Ny03LjM3Ny03Ljk5N3oiLz48L3N2Zz4=);
      background-repeat: no-repeat;
      background-position: center center;
      width: 36px;
      height: 24px;
      display: block;
      margin-right: 12px;
      position: relative;
      z-index: -1; }
    @media only screen and (max-width: 480px) {
      .t-Header-logo-link > span span:not(:only-child) {
        display: none; } }
  .t-Header-logo-link span {
    color: #FCFBFA;
    text-transform: uppercase; }

.t-Header-navBar {
  margin-right: 0;
  margin-left: auto; }
  @media only screen and (max-width: 992px) {
    .t-Header-navBar {
      margin-left: 0; } }

.t-Header-branding,
.t-Header-logo-oracle:before {
  height: auto; }

/* Navigation Bar Alignment
       ========================================================================== */
.t-NavigationBar {
  display: flex;
  align-items: center; }

.t-Button.t-Button--navBar {
  display: flex;
  height: 44px;
  min-width: 44px;
  border-radius: 4px;
  padding: 12px;
  align-items: center;
  justify-content: center; }
  .t-Button.t-Button--navBar:before, .t-Button.t-Button--navBar:after {
    content: none; }
  .t-Button.t-Button--navBar .t-Icon,
  .t-Button.t-Button--navBar .fa {
    width: 20px;
    height: 20px;
    line-height: 20px; }
    .t-Button.t-Button--navBar .t-Icon:before,
    .t-Button.t-Button--navBar .fa:before {
      font-size: 20px; }

body .t-Button.t-Button--navBar,
body .t-Header .t-Button--header {
  background-color: transparent;
  box-shadow: none;
  color: #FCFBFA; }
  body .t-Button.t-Button--navBar:hover, body .t-Button.t-Button--navBar:focus,
  body .t-Header .t-Button--header:hover,
  body .t-Header .t-Button--header:focus {
    background-color: rgba(49, 45, 42, 0.05);
    box-shadow: none;
    outline-color: #FCFBFA !important; }
  body .t-Button.t-Button--navBar:active,
  body .t-Header .t-Button--header:active {
    background-color: rgba(49, 45, 42, 0.08);
    box-shadow: none; }
  body .t-Button.t-Button--navBar.is-active,
  body .t-Header .t-Button--header.is-active {
    color: #FCFBFA;
    background-color: transparent; }

/* Search Icon
       ========================================================================== */
@media only screen and (min-width: 993px) {
  .header-search-item {
    display: none; } }

@media only screen and (max-width: 992px) {
  .header-search-item {
    display: inline-block; } }

/* Feedback Icon
       ========================================================================== */
@media only screen and (max-width: 480px) {
  .header-feedback-item {
    display: none; } }

/* User Icon
       ========================================================================== */
.js-headerUserMenu .t-Button--navBar .at-User {
  margin-top: -12px;
  margin-left: -12px;
  margin-bottom: -12px; }

.t-Button--navBar .a-Icon.icon-down-arrow {
  opacity: .6;
  margin-left: 6px; }
  @media only screen and (max-width: 480px) {
    .t-Button--navBar .a-Icon.icon-down-arrow {
      display: none; } }

/* Header Search Input
       ========================================================================== */
.at-Header-search {
  position: relative;
  display: block;
  margin: 2px 44px;
  transition: margin .2s ease;
  align-self: center;
  flex-grow: 1; }
  @media only screen and (max-width: 992px) {
    .at-Header-search {
      margin-right: 8px;
      margin-left: 8px; } }

@media only screen and (max-width: 992px) {
  .t-Header .at-Header-search {
    display: none !important; }
  .at-Search-responsive .at-Header-search {
    display: block; } }

input.at-Header-searchInput {
  display: block;
  box-sizing: border-box;
  padding: 10px 10px 10px 46px;
  min-width: 12.0rem;
  width: 100%;
  height: 4rem;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  background: #56504B;
  color: #FCFBFA;
  background-clip: padding-box;
  border: 0;
  border-radius: 40px;
  transition: .2s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  input.at-Header-searchInput::-webkit-input-placeholder {
    line-height: normal; }
  input.at-Header-searchInput:hover, input.at-Header-searchInput:focus, input.at-Header-searchInput:active {
    outline: none; }
  input.at-Header-searchInput:focus, input.at-Header-searchInput:active {
    color: #1A1816;
    background-color: #FCFBFA; }

.at-Header-searchLabel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin: 10px 16px;
  line-height: 20px;
  pointer-events: none;
  color: #FCFBFA;
  transition: color .2s ease; }
  .at-Header-search:focus-within .at-Header-searchLabel {
    color: #1A1816; }
  .at-Header-searchLabel .fa {
    font-size: 20px; }

/* Responsive Search Container
       ========================================================================== */
.at-Search-responsive {
  position: fixed;
  top: 0;
  left: 0;
  padding: 2.4rem;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: opacity .2s ease; }
  .at-Search-responsive.is-hidden {
    position: static;
    visibility: visible;
    opacity: 0; }
  .at-Search-responsive.is-visible {
    z-index: 10000;
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.7); }
  .at-Search-responsive .at-Header-searchLabel {
    top: 24px;
    left: 24px;
    margin: 1.2rem;
    transition: .2s ease;
    color: #1A1816; }
    .at-Search-responsive .at-Header-searchLabel .fa {
      width: 2.4rem;
      height: 2.4rem;
      font-size: 2.4rem;
      line-height: 1; }
  .at-Search-responsive input.at-Header-searchInput {
    padding: .8rem .8rem .8rem 4.4rem;
    max-width: 100%;
    height: 4.8rem;
    font-size: 1.8rem;
    transition: border .2s ease; }

@media (min-width: 769px) {
  .t-Header-nav {
    margin-top: 10px;
    margin-bottom: -10px;
    overflow: visible; } }

@media (min-width: 769px) {
  .t-Header-nav .t-NavTabs {
    overflow: visible;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
    background-color: transparent; } }

.t-Header-nav .t-NavTabs-item {
  border-color: transparent; }
/* ESF 7-DEC-2023
@media (min-width: 769px) {
  .t-Header-nav .t-NavTabs-link {
    padding: 12px; } }
*/
@media (min-width: 769px) {
  .t-Header-nav .t-NavTabs-item:hover,
  .t-Header-nav .t-NavTabs-item.is-active {
    background-color: transparent; } }

@media (min-width: 769px) {
  .t-Header-nav .t-NavTabs-item.is-active {
    font-weight: 700;
    box-shadow: inset #377E55 0 -4px 0; } }

/* Footer
  ========================================================================== */
/* Footer
   ========================================================================== */
.t-Footer {
  position: relative;
  flex-direction: column;
  background-color: #312D2A;
  color: rgba(252, 251, 250, 0.6);
  border-top: 0;
  padding: 56px 20px 44px;
  text-align: center;
  font-size: 14px;
  line-height: 20px; }
  .t-Footer a {
    color: #fcfbfa; }
    .t-Footer a:hover, .t-Footer a:focus {
      text-decoration: underline; }
  .t-Footer p {
    margin-bottom: 40px; }
    .t-Footer p:last-child {
      margin-bottom: 0; }

.t-Footer:before {
  background-image: url(../images/rw/footer-top-bg.png);
  background-size: auto 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  content: ''; }

.u-BuiltWithAPEX {
  display: inline; }
  .u-BuiltWithAPEX .fa-heart {
    color: #C74634;
    font-size: 20px;
    margin: -2px 4px;
    vertical-align: text-top;
    display: inline-block; }
  .u-BuiltWithAPEX:hover .fa-heart {
    color: #C74634; }

.t-Footer-body {
  margin: 0 auto;
  width: 100%; }

.t-Footer-apex {
  display: none; }

.t-Footer-top {
  margin-top: 40px; }

a.t-Footer-topButton {
  background-color: #FCFBFA;
  color: #FCFBFA;
  opacity: 1;
  padding: 10px;
  width: 44px;
  height: 44px;
  box-shadow: none !important; }
  a.t-Footer-topButton:hover {
    opacity: .8; }
  a.t-Footer-topButton .a-Icon {
    font-size: 24px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    color: #100F0E; }
    a.t-Footer-topButton .a-Icon:before {
      font-size: inherit; }

.o-Footer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 16px;
  padding-right: 16px; }
  .o-Footer:before, .o-Footer:after {
    content: ' ';
    display: table; }
  .o-Footer:after {
    clear: both; }
  @media only screen and (max-width: 768px) {
    .o-Footer {
      display: flex;
      flex-direction: column;
      align-items: center; } }

.o-Footer-tagLine {
  order: 2;
  margin: 0; }
  @media only screen and (min-width: 769px) {
    .o-Footer-tagLine {
      float: left; } }
  .o-Footer-tagLine a {
    display: block;
    color: #fff;
    outline: none;
    transition: color .2s ease; }
    .o-Footer-tagLine a:hover, .o-Footer-tagLine a:focus {
      color: rgba(255, 255, 255, 0.7); }
  .o-Footer-tagLine svg {
    display: block;
    fill: currentColor;
    width: 350px;
    max-width: 100%;
    height: auto; }

.o-Footer-socialLinks {
  margin: 0 auto 20px;
  text-align: center;
  order: 1; }
  @media only screen and (min-width: 769px) {
    .o-Footer-socialLinks {
      margin: 0;
      float: right; } }
  .o-Footer-socialLinks ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline;
    font-size: 0; }
    .o-Footer-socialLinks ul li {
      display: inline-block;
      margin: 5px; }
      @media only screen and (min-width: 769px) {
        .o-Footer-socialLinks ul li {
          margin: 8px; } }

a.o-Footer-socialLink {
  display: block;
  width: 29px;
  height: 28px;
  transition: background-color .1s ease, color .1s ease;
  background-color: #fff;
  color: #212120;
  border-radius: 2px;
  outline: none; }
  @media only screen and (min-width: 769px) {
    a.o-Footer-socialLink {
      width: 34px;
      height: 33px; } }
  a.o-Footer-socialLink:hover, a.o-Footer-socialLink:focus {
    background-color: rgba(255, 255, 255, 0.7);
    color: #212120; }
  a.o-Footer-socialLink svg {
    display: block;
    fill: currentColor;
    height: 100%;
    width: 100%; }

.o-Footer-links {
  margin: 0;
  margin-top: 20px;
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 12.5px;
  line-height: 15px;
  order: 3;
  color: inherit; }
  @media only screen and (min-width: 769px) {
    .o-Footer-links {
      float: right;
      margin-top: 12px;
      clear: both; } }
  .o-Footer-links li {
    font-size: inherit;
    display: inline; }
    .o-Footer-links li:not(:last-child) {
      padding-right: 10px;
      border-right: 1px solid rgba(255, 255, 255, 0.35);
      margin-right: 6px; }
    .o-Footer-links li a {
      outline: none;
      transition: color .2s ease; }
      .o-Footer-links li a:hover, .o-Footer-links li a:focus {
        color: #fff; }

html body .ui-dialog {
  box-shadow: 0 24px 48px -24px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.1);
  border: 0 !important;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff; }

html body .ui-dialog .ui-dialog-buttonpane {
  background-color: #fff;
  border-top-color: rgba(0, 0, 0, 0.1); }

html body .t-Dialog-header {
  box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.1); }

html body .ui-dialog .ui-dialog-titlebar-close {
  width: 44px;
  height: 44px;
  border-radius: 4px;
  color: #100F0E;
  background-color: transparent;
  z-index: 1; }
  html body .ui-dialog .ui-dialog-titlebar-close:hover {
    background-color: rgba(49, 45, 42, 0.05); }
  html body .ui-dialog .ui-dialog-titlebar-close:active {
    background-color: rgba(49, 45, 42, 0.08); }
  html body .ui-dialog .ui-dialog-titlebar-close:focus {
    box-shadow: none; }
  html body .ui-dialog .ui-dialog-titlebar-close .ui-icon-closethick {
    margin-top: -12px;
    margin-left: -12px; }
  html body .ui-dialog .ui-dialog-titlebar-close .ui-icon-closethick,
  html body .ui-dialog .ui-dialog-titlebar-close .ui-icon-closethick:before {
    font-size: 20px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center; }

html body .ui-dialog .ui-dialog-titlebar {
  padding: 20px;
  background: none;
  border: 0; }

html body .ui-dialog .ui-dialog-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px; }

html body .t-Dialog-body {
  padding: 16px 20px; }

html body .t-Dialog-footer .t-ButtonRegion {
  border: 0; }

html body .t-ButtonRegion-col--content,
html body .t-ButtonRegion-col--left .t-ButtonRegion-buttons,
html body .t-ButtonRegion-col--right .t-ButtonRegion-buttons {
  padding: 12px 16px; }

.t-Body-title .t-HeroRegion-title,
.t-Body-title .t-HeroRegion-col--content,
.t-Body-title .t-Breadcrumb-item,
.t-Body-title .t-Breadcrumb-item a,
.t-Body-title .t-BreadcrumbRegion--useBreadcrumbTitle .t-Breadcrumb-item.is-active,
.t-Body-title .t-BreadcrumbRegion-titleText {
  color: inherit; }

.t-Body-title .t-Breadcrumb-item:after {
  color: rgba(252, 251, 250, 0.6); }

.t-Body-title {
  background-color: #312D2A;
  color: #FCFBFA;
  border-bottom: 0;
  background-image: url(../images/rw/hero-2b-bg.png), linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)); }
  .t-Body-title .t-HeroRegion-title {
    color: inherit; }
  .t-Body-title .t-Button:before, .t-Body-title .t-Button:after {
    content: none; }
  .t-Body-title .t-Button--link .t-Icon, .t-Body-title .t-Button--link.t-Button, .t-Body-title .t-Button:not(.t-Button--simple):not(.t-Button--hot) {
    color: #FCFBFA; }
    .t-Body-title .t-Button--link .t-Icon:focus, .t-Body-title .t-Button--link.t-Button:focus, .t-Body-title .t-Button:not(.t-Button--simple):not(.t-Button--hot):focus {
      outline-color: #FCFBFA !important; }
  .t-Body-title .t-Button--noUI.t-Button--danger,
  .t-Body-title .t-Button--link.t-Button--danger,
  .t-Body-title .t-Button--noUI.t-Button--danger .t-Icon,
  .t-Body-title .t-Button--link.t-Button--danger .t-Icon {
    color: #EA7362 !important; }
  .t-Body-title .t-ButtonRegion {
    padding-top: 20px;
    padding-bottom: 20px; }
  .t-Body-title.t-Body-title-shrink .t-ButtonRegion {
    padding-top: 0;
    padding-bottom: 0; }

/* Hero Region
   ========================================================================== */
.t-HeroRegion-wrap {
  padding-top: 36px;
  padding-bottom: 36px; }
  @media only screen and (max-width: 992px) {
    .t-HeroRegion-wrap {
      padding-top: 16px;
      padding-bottom: 16px; } }
  .t-Body-title-shrink .t-HeroRegion-wrap {
    padding-top: 16px;
    padding-bottom: 16px; }

.t-HeroRegion-col.t-HeroRegion-col--right {
  flex-grow: 0; }

.t-HeroRegion-icon {
  margin-right: 12px;
  width: 44px;
  height: 44px;
  font-weight: 500;
  font-size: 16px;
  line-height: 44px;
  border-radius: 4px; }

.t-HeroRegion-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px; }
  .t-HeroRegion-title a {
    color: inherit; }
    .t-HeroRegion-title a:hover {
      text-decoration: underline; }

@media only screen and (max-width: 640px) {
  .t-HeroRegion-icon.t-Icon {
    font-size: 16px !important; } }

.t-HeroRegion:not(.at-HeroRegion--hideBack):not(.at-HeroRegion--topLevel) .t-HeroRegion-title > a {
  position: relative; }
  .t-HeroRegion:not(.at-HeroRegion--hideBack):not(.at-HeroRegion--topLevel) .t-HeroRegion-title > a:before {
    opacity: 0;
    transition: opacity 0.15s ease;
    position: absolute;
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-right: 12px;
    background-color: #fff;
    color: rgba(16, 15, 14, 0.6);
    display: block;
    width: 44px;
    height: 44px;
    border-radius: 4px;
    content: "\f053";
    font-family: 'Font APEX Large';
    text-align: center;
    line-height: 44px;
    font-size: 28px; }
  .t-HeroRegion:not(.at-HeroRegion--hideBack):not(.at-HeroRegion--topLevel) .t-HeroRegion-title > a:hover:before {
    opacity: 1; }

.t-Body-title .t-Tabs--simple {
  margin: 0;
  box-shadow: none;
  overflow: visible; }
  .t-Body-title .t-Tabs--simple .t-Tabs-item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 0;
    margin-right: 40px;
    transition: margin .15s ease; }
    @media only screen and (min-width: 641px) {
      .t-Body-title .t-Tabs--simple .t-Tabs-item {
        margin-right: 48px; } }
    .t-Body-title .t-Tabs--simple .t-Tabs-item:last-child {
      margin-right: 0; }
  .t-Body-title .t-Tabs--simple .t-Icon {
    color: inherit;
    margin-right: 12px;
    vertical-align: top;
    font-size: 20px;
    width: 20px;
    height: 20px;
    line-height: 20px; }
  .t-Body-title .t-Tabs--simple .t-Tabs-label {
    display: inline-block;
    vertical-align: top; }
  .t-Body-title .t-Tabs--simple .t-Tabs-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 4px 0 16px;
    color: #FCFBFA;
    margin: 0;
    transition: box-shadow .15s ease, color .15s ease; }
    @media only screen and (max-width: 640px) {
      .t-Body-title .t-Tabs--simple .t-Tabs-link {
        font-size: 14px; } }
    .t-Body-title .t-Tabs--simple .t-Tabs-link:active {
      background-color: transparent; }
    .t-Body-title .t-Tabs--simple .t-Tabs-link:hover, .t-Body-title .t-Tabs--simple .t-Tabs-link:focus {
      box-shadow: inset rgba(255, 255, 255, 0.35) 0 -4px 0 0; }
  .t-Body-title .t-Tabs--simple .t-Tabs-item.is-active .t-Tabs-link {
    box-shadow: inset rgba(255, 255, 255, 0.6) 0 -4px 0 0; }

.a-Menu-content,
.t-NavigationBar-menu .a-Menu-content {
  border: none;
  box-shadow: 0 12px 24px -12px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 8px 0;
  min-width: 200px;
  background-color: #fff; }

.a-Menu-content .a-Menu-statusCol,
.a-Menu .a-Menu-item .a-Menu-statusCol {
  padding: 10px 16px;
  width: auto; }
  .a-Menu-content .a-Menu-statusCol .fa,
  .a-Menu .a-Menu-item .a-Menu-statusCol .fa {
    font-size: 20px !important; }

.a-Menu .a-Menu-item {
  font-size: 15px;
  line-height: 40px;
  color: #1A1816; }
  .a-Menu .a-Menu-item > .a-Menu-inner .a-Menu-statusCol {
    color: inherit !important; }

.t-NavigationBar-menu .a-Menu-item .a-Menu-label,
.t-NavigationBar-menu.a-Menu .a-Menu-item a,
.t-NavigationBar-menu.a-Menu .a-Menu-item .a-Menu-statusCol {
  color: inherit !important; }

.a-Menu .a-Menu-item .a-Menu-subMenuCol {
  height: 32px;
  padding: 8px 4px 8px 0; }

.a-Menu-itemSep .a-Menu-hSeparator {
  border-color: rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  padding-top: 4px;
  padding-bottom: 4px; }

#group_settings_menu .a-Menu-itemSep .a-Menu-hSeparator {
  border-color: rgba(0, 0, 0, 0.05); }

.t-NavigationBar-menu .a-Menu-item.is-focused,
.t-NavigationBar-menu .a-Menu-item.is-expanded,
.a-Menu .a-Menu-item.is-expanded,
.a-Menu .a-Menu-item.is-focused {
  background-color: #F1F0EB;
  background: rgba(49, 45, 42, 0.05) !important;
  color: inherit; }

html .a-Menu-item.is-focused {
  outline: none !important; }

.at-Hero {
  background-color: #2e2e2e;
  background-image: url(../images/hero-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  color: #fff;
  padding-top: 22px;
  padding-bottom: 22px;
  background-color: #312D2A;
  color: #FCFBFA;
  border-bottom: 0;
  background-image: url(../images/rw/hero-3b-bg.png), linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)); }
  .transparent-header .at-Hero {
    padding-top: 110px;
    padding-bottom: 84px;
    margin-top: -78px; }
  .at-Hero h1 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0;
    font-weight: 700; }
    .at-Hero h1 strong {
      font-weight: inherit; }
  .at-Hero p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 13px; }
  .at-Hero a {
    color: inherit;
    text-decoration: underline; }
  .at-Hero .at-Hero-content:last-child p:last-child {
    margin-bottom: 0; }
  .at-Hero--developer {
    background-image: url(../images/developer-bg.jpg); }
  .at-Hero--intro {
    padding-top: 22px;
    padding-bottom: 22px;
    text-align: center; }
    .transparent-header .at-Hero--intro {
      padding-top: 142px;
      padding-bottom: 64px;
      margin-top: -78px; }
      @media only screen and (max-width: 768px) {
        .transparent-header .at-Hero--intro {
          padding-top: 110px;
          padding-bottom: 32px;
          text-align: center; } }
    .at-Hero--intro h1 {
      font-size: 30px;
      line-height: 1;
      margin-bottom: 22px; }
      @media only screen and (max-width: 768px) {
        .at-Hero--intro h1 {
          font-size: 30px;
          margin-bottom: 20px; } }
      .at-Hero--intro h1 + p {
        margin-top: -16px; }
    .at-Hero--intro .at-HeroSearch {
      max-width: 820px; }

.at-Hero-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 36px;
  padding-right: 36px; }
  @media (max-width: 992px) {
    .at-Hero-content {
      padding-left: 20px;
      padding-right: 20px; } }
  .at-Hero-content--withAction {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.transparent-header .at-HeroButton-back {
  color: #fff; }

.at-HeroSearch {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  max-width: 1440px;
  padding-left: 36px;
  padding-right: 36px; }
  @media (max-width: 992px) {
    .at-HeroSearch {
      padding-left: 20px;
      padding-right: 20px; } }
  .at-HeroSearch--full {
    max-width: 1440px !important; }
  @media only screen and (max-width: 768px) {
    .at-HeroSearch {
      flex-direction: column; }
      .at-HeroSearch .at-HeroSearch-text,
      .at-HeroSearch .at-HeroSearch-region,
      .at-HeroSearch .at-HeroSearch-type {
        margin-right: 0 !important;
        margin-bottom: 8px;
        width: 100%; }
        .at-HeroSearch .at-HeroSearch-text .apex-item-select,
        .at-HeroSearch .at-HeroSearch-region .apex-item-select,
        .at-HeroSearch .at-HeroSearch-type .apex-item-select {
          width: 100%;
          max-width: 100%; }
      .at-HeroSearch .at-HeroSearch-action {
        width: 240px; } }
  .at-HeroSearch .t-Form-inputContainer {
    padding: 0;
    overflow: visible; }
  .at-HeroSearch .at-HeroSearch-text,
  .at-HeroSearch .at-HeroSearch-region,
  .at-HeroSearch .at-HeroSearch-type {
    padding: 0;
    margin-right: 8px;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto; }
  .at-HeroSearch .at-HeroSearch-region {
    flex-grow: 0; }
  .at-HeroSearch .at-HeroSearch-action {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    font-size: 20px;
    line-height: 8px;
    /* padding: 12px 32px;*/
    box-shadow: rgba(0, 0, 0, 0.25) 0 2px 4px -2px !important;
    position: relative;
    top: -3px; }
    .at-HeroSearch .at-HeroSearch-action:before, .at-HeroSearch .at-HeroSearch-action:after {
      content: none !important; }
    .at-HeroSearch .at-HeroSearch-action:focus {
      box-shadow: rgba(0, 0, 0, 0.25) 0 2px 4px -2px, inset #00758F 0 0 0 1px, inset #fff 0 0 0 2px !important; }
    .at-HeroSearch .at-HeroSearch-action:focus:active {
      box-shadow: rgba(0, 0, 0, 0.25) 0 2px 4px -2px, inset #00758F 0 0 0 1px !important; }

.at-HeroSearch-text .apex-item-text {
  background-color: #fff;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px -2px;
  display: block;
  font-size: 18px;
  line-height: 1;
  width: 100%;
  padding: 18px 16px;
  height: 46px;
  text-indent: 34px;
  color: rgba(0, 0, 0, 0.7);
  transition: box-shadow .2s ease, color .2s ease; }
  .at-HeroSearch-text .apex-item-text:focus {
    color: #000;
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px -2px, inset #fff 0 0 0 1px, inset #00758F 0 0 0 2px; }
    .at-HeroSearch-text .apex-item-text:focus + .apex-item-icon {
      color: rgba(0, 0, 0, 0.7); }

.at-HeroSearch-text .apex-item-icon {
  color: rgba(0, 0, 0, 0.35);
  font-size: 24px;
  left: 56px;
  margin-left: -56px;
  padding: 14px;
  transition: color .2s ease; }

.at-HeroSearch-region .apex-item-select,
.at-HeroSearch-type .apex-item-select {
  background-color: #fff;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px -2px;
  display: block;
  font-size: 20px;
  line-height: 32px;
  padding: 12px 16px;
  padding-right: 48px;
  text-overflow: ellipsis;
  min-height: 56px;
  max-height: 56px;
  color: rgba(0, 0, 0, 0.7);
  max-width: 220px;
  width: 100%;
  background-size: 56px 28px;
  transition: box-shadow .2s ease, color .2s ease; }
  .at-HeroSearch-region .apex-item-select:focus,
  .at-HeroSearch-type .apex-item-select:focus {
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px -2px, inset #fff 0 0 0 1px, inset #00758F 0 0 0 2px;
    color: #000; }

.at-HeroSearch-type .apex-item-select {
  max-width: 400px; }

.at-Hero-action {
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px; }

.at-Hero-action .t-Button {
  color: #fff;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.1) !important; }
  .at-Hero-action .t-Button:before, .at-Hero-action .t-Button:after {
    content: none; }
  .at-Hero-action .t-Button:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
    box-shadow: none !important; }
  .at-Hero-action .t-Button:active {
    background-color: rgba(255, 255, 255, 0.3) !important;
    box-shadow: none !important; }
  .at-Hero-action .t-Button:focus, .at-Hero-action .t-Button:active:focus {
    box-shadow: 0 0 0 1px #fff inset !important; }

.at-Hero-content--withAction .t-Button {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2) !important; }
  .at-Hero-content--withAction .t-Button:before, .at-Hero-content--withAction .t-Button:after {
    content: none; }
  .at-Hero-content--withAction .t-Button:hover {
    background-color: rgba(0, 0, 0, 0.25) !important;
    box-shadow: none !important; }
  .at-Hero-content--withAction .t-Button:active {
    background-color: rgba(0, 0, 0, 0.5) !important;
    box-shadow: none !important; }
  .at-Hero-content--withAction .t-Button:focus, .at-Hero-content--withAction .t-Button:active:focus {
    box-shadow: 0 0 0 1px #fff inset !important; }

.at-Hero-content--withAction .no-Title {
  padding-top: 32px;
  padding-bottom: 25px; }

/* Modified 10 09 2019 by ESF: reduced title font size */
.dg-ContentRowReport-item:not(:only-child):not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.075); }

.dg-ContentRowReport + .t-Report-pagination {
  border-top: 1px solid rgba(0, 0, 0, 0.075); }

.dg-ContentRowReport-itemWrap {
  padding: 16px;
  display: flex;
  flex-wrap: nowrap; }
  @media only screen and (max-width: 992px) {
    .dg-ContentRowReport-itemWrap {
      padding: 12px; } }
  a.dg-ContentRowReport-itemWrap {
    text-decoration: none !important;
    outline: none; }

.dg-ContentRowReport-itemSelection {
  margin-right: 16px;
  font-size: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  @media only screen and (max-width: 992px) {
    .dg-ContentRowReport-itemSelection {
      margin-right: 12px; } }
  .dg-ContentRowReport-itemSelection input {
    font-size: 16px;
    margin: 0; }
  .dg-ContentRowReport-itemSelection:empty {
    display: none; }

.dg-ContentRowReport-itemIconWrap {
  margin-right: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  padding-top: 2px;
  padding-bottom: 2px;
  color: rgba(0, 0, 0, 0.5);
  max-height: 44px;
  align-items: center;
  display: flex; }
  .dg-ContentRowReport-itemIconWrap:empty {
    display: none; }
  @media only screen and (max-width: 640px) {
    .dg-ContentRowReport-itemIconWrap .dg-Icon {
      width: 24px;
      height: 24px; } }

.dg-ContentRowReport-itemBody {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: flex;
  flex-wrap: nowrap; }
  @media only screen and (max-width: 640px) {
    .dg-ContentRowReport-itemBody {
      flex-direction: column; } }
  .dg-ContentRowReport-itemBody p:empty {
    display: none; }

.dg-ContentRowReport-itemContent {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto; }

.dg-ContentRowReport-itemTitle {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin-right: 8px;
  display: inline-block;
  vertical-align: top; }
  .dg-ContentRowReport-itemTitle > label {
    display: block;
    cursor: pointer; }
  .dg-ContentRowReport-itemTitle a:not([class]):hover {
    text-decoration: underline; }
  .dg-QuestionList-badge + .dg-ContentRowReport-itemTitle {
    margin-left: 4px; }
  .is-read .dg-ContentRowReport-itemTitle {
    font-weight: 400; }

.dg-ContentRowReport-itemDescription {
  margin: 4px 0 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55); }
  .dg-ContentRowReport-itemDescription:empty {
    display: none; }
  .dg-ContentRowReport-itemDescription .dg-QuestionList-badges {
    margin-top: 4px; }
    .dg-ContentRowReport-itemDescription .dg-QuestionList-badges:empty {
      display: none; }
  .dg-ContentRowReport-itemDescription .dg-QuestionList-author {
    color: black;
    font-weight: 500; }

.dg-QuestionList-tag {
  display: inline-block;
  padding: 3px 0;
  font-size: 11px;
  line-height: 12px;
  margin: 2px 8px 2px 0;
  border-radius: 2px; }
  .dg-QuestionList-tag:before {
    content: '';
    width: 12px;
    height: 12px;
    margin-right: 4px;
    display: inline-block;
    vertical-align: top;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiI+PHBhdGggZmlsbD0iI2FmYmFjNSIgZD0iTTExLjQzNyA0Ljk1MmMuNzQuNzQxLjc1MiAxLjk1Ni4wMjkgMi43MTNsLTMuNTc3IDMuNzM4QTEuOTIxIDEuOTIxIDAgMCAxIDYuNTMyIDEySDYuNDljLS41MDIgMC0uOTc3LS4xOTEtMS4zNC0uNTRMLjU5NSA3LjA4QTEuOTUgMS45NSAwIDAgMSAwIDUuNjgxVjEuOTk5YzAtMS4wNjkuOTMyLTIgMi0yaDMuNjkxYy41MDkgMCAxLjAwOC4yMDYgMS4zNjkuNTY4bDQuMzc3IDQuMzg0ek00IDNhMSAxIDAgMSAwLTIgMCAxIDEgMCAwIDAgMiAweiIvPjwvc3ZnPg==");
    background-position: 50%;
    background-repeat: no-repeat;
    opacity: .5; }
  .dg-QuestionList-tag.is-category:before {
    opacity: 1;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMCI+PHBhdGggZmlsbD0iI2FmYmFjNSIgZD0iTTUuOTE0IDJhLjUwNC41MDQgMCAwIDEtLjM1My0uMTQ2TDQuMTQ2LjQzOUExLjQ4NiAxLjQ4NiAwIDAgMCAzLjA5IDBIMWExIDEgMCAwIDAtMSAxdjhhMSAxIDAgMCAwIDEgMWgxMGExIDEgMCAwIDAgMS0xVjNhMSAxIDAgMCAwLTEtMUg1LjkxNHpNMi4wNDIgNWMtLjI5OSAwLS41NDItLjIyNC0uNTQyLS41cy4yNDMtLjUuNTQyLS41aDcuOTE3Yy4yOTkgMCAuNTQyLjIyNC41NDIuNXMtLjI0My41LS41NDIuNUgyLjA0MnoiLz48L3N2Zz4="); }

.dg-ContentRowReport-itemMisc {
  margin-left: 16px;
  margin-right: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto; }
  @media only screen and (max-width: 992px) {
    .dg-ContentRowReport-itemMisc {
      margin-left: 12px;
      margin-right: 12px; } }
  @media only screen and (max-width: 640px) {
    .dg-ContentRowReport-itemMisc {
      margin-left: 0;
      margin-right: 0;
      margin-top: 4px;
      flex-grow: 1; } }
  .dg-ContentRowReport-itemMisc:empty {
    display: none; }

.dg-QuestionList-stats {
  padding-right: 16px;
  padding-top: 2px;
  padding-bottom: 2px; }
  @media only screen and (max-width: 640px) {
    .dg-QuestionList-stats {
      padding-right: 0;
      display: flex; }
      .dg-QuestionList-stats .dg-QuestionList-stat .dg-QuestionList-value {
        min-width: 0; } }
  @media only screen and (max-width: 992px) {
    .dg-QuestionList-stats {
      padding-right: 12px; } }

.dg-QuestionList-stat {
  font-size: 12px;
  line-height: 20px; }
  @media only screen and (max-width: 640px) {
    .dg-QuestionList-stat {
      margin-right: 12px; } }
  .dg-QuestionList-stat .dg-QuestionList-value,
  .dg-QuestionList-stat .dg-QuestionList-label {
    font-size: inherit;
    line-height: inherit;
    display: inline-block;
    vertical-align: top; }
  .dg-QuestionList-stat .dg-QuestionList-value {
    min-width: 40px;
    text-align: right; }
  .dg-QuestionList-stat .dg-QuestionList-label {
    color: rgba(0, 0, 0, 0.55); }

.dg-ContentRowReport-itemActions {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  align-items: center;
  max-height: 44px; }
  @media only screen and (max-width: 640px) {
    .dg-ContentRowReport-itemActions {
      display: none; } }
  .dg-ContentRowReport-itemActions:empty {
    display: none; }
  .dg-ContentRowReport-itemActions .dg-User {
    width: 160px; }
    @media only screen and (max-width: 992px) {
      .dg-ContentRowReport-itemActions .dg-User {
        width: 32px; }
        .dg-ContentRowReport-itemActions .dg-User .dg-User-bit {
          display: none; } }
  .dg-ContentRowReport-itemActions .dg-User-name {
    font-weight: 500;
    font-size: 12px; }

.dg-ContentRowReport .dg-Report-percentChart {
  width: 100%;
  margin: 4px 0;
  height: 8px;
  border-radius: 8px; }

.dg-ContentRowReport--hideSelection .dg-ContentRowReport-itemSelection {
  display: none !important; }

.dg-ContentRowReport--hideIcons .dg-ContentRowReport-itemIconWrap {
  display: none !important; }

.dg-ContentRowReport--hideTitle .dg-ContentRowReport-itemTitle {
  display: none !important; }

.dg-ContentRowReport--hideTitle .dg-ContentRowReport-itemDescription {
  margin-top: 0;
  color: inherit; }

.dg-ContentRowReport--hideMisc .dg-ContentRowReport-itemMisc {
  display: none !important; }

.dg-ContentRowReport--hideActions .dg-ContentRowReport-itemActions {
  display: none !important; }

.dg-ContentRowReport--hideBorders .dg-ContentRowReport-item {
  border-bottom: 0 !important; }

.dg-ContentRowReport--removePadding .dg-ContentRowReport-itemWrap {
  padding-left: 0;
  padding-right: 0;
  padding-top: 8px;
  padding-bottom: 8px; }

.dg-ContentRowReport--removePadding .dg-ContentRowReport-item:first-child .dg-ContentRowReport-itemWrap {
  padding-top: 0; }

.dg-ContentRowReport--removePadding .dg-ContentRowReport-item:last-child .dg-ContentRowReport-itemWrap {
  padding-bottom: 0; }

/* When Question is your own
   ========================================================================== */
.dg-ContentRowReport-item.is-mine .dg-ContentRowReport-itemWrap {
  box-shadow: 4px 0 0 inset #afbac5; }

.page-1 {
  /* Search bar */
  /* Align buttons + form items in Search Toolbar */
  /* New CSS */ }
  .page-1 .clear-search {
    height: 3.2rem; }
  .page-1 .t-Comments-userIcon {
    height: 48px;
    width: 48px; }
  .page-1 .t-Comments-userIcon img {
    height: 48px; }
  .page-1 #upcoming-events-reg .dates {
    display: block;
    font-size: 1.1rem;
    color: #636363; }
  .page-1 span.month {
    display: block;
    font-size: 10px;
    font-weight: bold;
    line-height: 20px;
    background-color: #DA1B1B;
    border-radius: 2px 2px 0 0;
    text-transform: uppercase; }
  .page-1 span.day {
    display: block;
    line-height: 20px;
    padding: 3px 0 5px;
    background-color: #FFF;
    color: #404040;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
    border-radius: 0 0 2px 2px;
    font-size: 16px;
    font-weight: normal; }
  .page-1 .t-Cards--basic .t-Card .t-Card-icon .t-Icon {
    background-color: transparent; }
  .page-1 .t-Region.t-Region--noUI .t-Region-body {
    padding: 12px; }
  .page-1 .ate-question-link {
    font-size: 14px;
    vertical-align: middle; }
  .page-1 .ate-Messages {
    list-style-type: none;
    margin: 0; }
  .page-1 .ate-Messages li {
    border-bottom: 1px solid #F0F0F0;
    padding-bottom: 12px;
    margin-bottom: 12px; }
  .page-1 .ate-Messages li:last-child {
    margin-bottom: 0;
    border-bottom-width: 0; }
  .page-1 .ate-Messages-imgLink {
    display: block; }
  .page-1 .ate-Messages-img {
    display: block;
    max-width: 100%;
    margin: 0 auto; }
  .page-1 .ate-Messages p {
    margin: 0 1.2rem 0 0; }
  .page-1 .u-Processing {
    top: 20px !important; }
  .page-1 .ate-Experts {
    margin: 0;
    list-style: none;
    padding: 0; }
  .page-1 .ate-Experts li {
    display: block;
    overflow: hidden;
    padding: 12px;
    border-bottom: 1px solid #F0F0F0; }
  .page-1 .ate-Experts li:last-child {
    border-bottom-width: 0; }
  .page-1 .ate-Expert-photo {
    float: left;
    margin-right: 8px; }
  .page-1 .ate-Expert-photo img {
    border-radius: 6px;
    width: 64px;
    height: 64px; }
  .page-1 .ate-Expert-info {
    overflow: hidden; }
  .page-1 .ate-Expert-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    display: block; }
  .page-1 .ate-Expert-social {
    font-size: 11px;
    line-height: 16px;
    display: block; }
  .page-1 .ate-Expert-socialIcon {
    font-size: 14px;
    display: inline-block;
    vertical-align: top; }
  .page-1 .ate-Expert-socialIcon.fa-twitter {
    color: #00ACED; }
  .page-1 .ate-Expert-socialIcon.fa-youtube-play {
    color: #CE1312; }
  .page-1 .ate-Expert-bio {
    margin: 8px 0;
    font-size: 12px;
    line-height: 16px; }
  .page-1 .ate-Expert-learnMore {
    font-size: 11px;
    line-height: 16px; }
  .page-1 .ate-Expert-status {
    font-size: 11px;
    color: #707070;
    display: block;
    line-height: 16px; }
  .page-1 .ate-Experts--full .ate-Expert-bio {
    font-size: 14px;
    line-height: 20px; }
  .page-1 .ate-Experts--full .ate-Expert-profile p {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 8px 0; }
  .page-1 .ate-Experts--full .ate-Expert-profile p:last-child {
    margin-bottom: 0; }
  .page-1 .ate-Experts--full li {
    padding: 16px; }
  .page-1 .ate-Experts--full .ate-Expert-name {
    font-size: 16px; }
  .page-1 .ate-Experts--full .ate-Expert-social,
  .page-1 .ate-Experts--full .ate-Expert-status {
    font-size: 12px;
    margin-top: 8px; }
  .page-1 #upcoming-events-reg .t-Card-info {
    white-space: normal;
    overflow: visible; }
  .page-1 .ate-SearchBar .u-Processing {
    margin-top: 4px; }
  .page-1 .ate-SearchBar input[type=text].t-Form-searchField {
    width: 280px; }
  @media only screen and (max-width: 640px) {
    .page-1 .ate-SearchBar input[type=text].t-Form-searchField {
      width: 50%; }
    .page-1 .ate-SearchBar.t-Form--floatLeft .col {
      float: left;
      /* width: 100% !important; */ }
    .page-1 .ate-SearchBar .t-ButtonRegion-col {
      display: block;
      width: auto; }
    .page-1 .ate-SearchBar .t-ButtonRegion-col--right .t-ButtonRegion-buttons {
      text-align: left;
      padding: 0 16px 16px 16px; }
    .page-1 .ate-SearchBar .t-ButtonRegion-col--right .t-ButtonRegion-buttons .t-Button {
      display: block;
      width: 100%;
      font-weight: bold; }
    .page-1 div#P1_SEARCH_CRITERIA_CONTAINER {
      display: inline-block;
      vertical-align: top;
      margin-top: 0; } }
  @media only screen and (max-width: 768px) {
    .page-1 .ate-SearchBar input[type=text].t-Form-searchField {
      width: 180px; } }
  .page-1 .ate-SearchBar-input .t-Form-inputContainer {
    position: relative; }
  .page-1 button#clear-btn {
    position: absolute;
    right: 0px;
    display: none;
    top: 0%;
    height: 47px; }
  .page-1 .ate-SearchBar input[type=text].t-Form-searchField {
    padding-right: 32px; }
  .page-1 #COL06,
  .page-1 #COL07 {
    min-width: 115px; }
  .page-1 .ate-Hero {
    background-image: url("../images/developer-bg.jpg");
    padding: 24px 0; }
  .page-1 .ate-SearchBar {
    max-width: 990px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 2px; }
  .page-1 .ate-Expert-photo img {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    float: left;
    margin-right: 4px;
    margin-bottom: 4px; }
  .page-1 #outerlink {
    position: relative; }

.page-2 #P2_GUIDELINES {
  margin-top: 8px; }

.page-2 .personal-info {
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 16px;
  margin-bottom: 16px; }

.page-2 .slim-alert .t-Alert-wrap {
  padding: 8px 16px; }

.page-2 .slim-alert.t-Alert {
  float: left; }

.page-2 .slim-alert.t-Alert--horizontal .t-Alert-body {
  margin: 0; }

.page-2 .slim-alert .t-Alert-content {
  display: flex;
  align-items: center; }

.page-3 pre {
  background-color: #F8F8F8;
  padding: 12px;
  font-size: 11px;
  float: left;
  border-radius: 2px;
  margin: 0;
  overflow: auto;
  z-index: 1;
  clear: left;
  line-height: 14.5px; }

.page-3 .apex-item-display-only {
  min-height: 2.4rem;
  box-shadow: none;
  font-weight: 400;
  line-height: 24px; }

.page-5 {
  /* Search bar */
  /* Align buttons + form items in Search Toolbar */
  /* New CSS */ }
  .page-5 .clear-search {
    height: 3.2rem; }
  .page-5 .t-Comments-userIcon {
    height: 48px;
    width: 48px; }
  .page-5 .t-Comments-userIcon img {
    height: 48px; }
  .page-5 #upcoming-events-reg .dates {
    display: block;
    font-size: 1.1rem;
    color: #636363; }
  .page-5 span.month {
    display: block;
    font-size: 10px;
    font-weight: bold;
    line-height: 20px;
    background-color: #DA1B1B;
    border-radius: 2px 2px 0 0;
    text-transform: uppercase; }
  .page-5 span.day {
    display: block;
    line-height: 20px;
    padding: 3px 0 5px;
    background-color: #FFF;
    color: #404040;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
    border-radius: 0 0 2px 2px;
    font-size: 16px;
    font-weight: normal; }
  .page-5 .t-Cards--basic .t-Card .t-Card-icon .t-Icon {
    background-color: transparent; }
  .page-5 .t-Region.t-Region--noUI .t-Region-body {
    padding: 12px; }
  .page-5 .ate-question-link {
    font-size: 14px; }
  .page-5 .ate-Messages {
    list-style-type: none;
    margin: 0; }
  .page-5 .ate-Messages li {
    border-bottom: 1px solid #F0F0F0;
    padding-bottom: 12px;
    margin-bottom: 12px; }
  .page-5 .ate-Messages li:last-child {
    margin-bottom: 0;
    border-bottom-width: 0; }
  .page-5 .ate-Messages-imgLink {
    display: block; }
  .page-5 .ate-Messages-img {
    display: block;
    max-width: 100%;
    margin: 0 auto; }
  .page-5 .ate-Messages p {
    margin: 0 1.2rem 0 0; }
  .page-5 .u-Processing {
    top: 20px !important; }
  .page-5 .ate-Experts {
    margin: 0;
    list-style: none;
    padding: 0; }
  .page-5 .ate-Experts li {
    display: block;
    overflow: hidden;
    padding: 12px;
    border-bottom: 1px solid #F0F0F0; }
  .page-5 .ate-Experts li:last-child {
    border-bottom-width: 0; }
  .page-5 .ate-Expert-photo {
    float: left;
    margin-right: 8px; }
  .page-5 .ate-Expert-photo img {
    border-radius: 6px;
    width: 64px;
    height: 64px; }
  .page-5 .ate-Expert-info {
    overflow: hidden; }
  .page-5 .ate-Expert-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    display: block; }
  .page-5 .ate-Expert-social {
    font-size: 11px;
    line-height: 16px;
    display: block; }
  .page-5 .ate-Expert-socialIcon {
    font-size: 14px;
    display: inline-block;
    vertical-align: top; }
  .page-5 .ate-Expert-socialIcon.fa-twitter {
    color: #00ACED; }
  .page-5 .ate-Expert-socialIcon.fa-youtube-play {
    color: #CE1312; }
  .page-5 .ate-Expert-bio {
    margin: 8px 0;
    font-size: 12px;
    line-height: 16px; }
  .page-5 .ate-Expert-learnMore {
    font-size: 11px;
    line-height: 16px; }
  .page-5 .ate-Expert-status {
    font-size: 11px;
    color: #707070;
    display: block;
    line-height: 16px; }
  .page-5 .ate-Experts--full .ate-Expert-bio {
    font-size: 14px;
    line-height: 20px; }
  .page-5 .ate-Experts--full .ate-Expert-profile p {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 8px 0; }
  .page-5 .ate-Experts--full .ate-Expert-profile p:last-child {
    margin-bottom: 0; }
  .page-5 .ate-Experts--full li {
    padding: 16px; }
  .page-5 .ate-Experts--full .ate-Expert-name {
    font-size: 16px; }
  .page-5 .ate-Experts--full .ate-Expert-social,
  .page-5 .ate-Experts--full .ate-Expert-status {
    font-size: 12px;
    margin-top: 8px; }
  .page-5 #upcoming-events-reg .t-Card-info {
    white-space: normal;
    overflow: visible; }
  .page-5 .ate-SearchBar .u-Processing {
    margin-top: 4px; }
  .page-5 .ate-SearchBar input[type=text].t-Form-searchField {
    width: 280px; }
  @media only screen and (max-width: 640px) {
    .page-5 .ate-SearchBar input[type=text].t-Form-searchField {
      width: 50%; }
    .page-5 .ate-SearchBar.t-Form--floatLeft .col {
      float: left;
      /* width: 100% !important; */ }
    .page-5 .ate-SearchBar .t-ButtonRegion-col {
      display: block;
      width: auto; }
    .page-5 .ate-SearchBar .t-ButtonRegion-col--right .t-ButtonRegion-buttons {
      text-align: left;
      padding: 0 16px 16px 16px; }
    .page-5 .ate-SearchBar .t-ButtonRegion-col--right .t-ButtonRegion-buttons .t-Button {
      display: block;
      width: 100%;
      font-weight: bold; }
    .page-5 div#P1_SEARCH_CRITERIA_CONTAINER {
      display: inline-block;
      vertical-align: top;
      margin-top: 0; } }
  @media only screen and (max-width: 768px) {
    .page-5 .ate-SearchBar input[type=text].t-Form-searchField {
      width: 180px; } }
  .page-5 .ate-SearchBar-input .t-Form-inputContainer {
    position: relative; }
  .page-5 button#clear-btn {
    position: absolute;
    right: 8px;
    display: none; }
  .page-5 .ate-SearchBar input[type=text].t-Form-searchField {
    padding-right: 32px; }
  .page-5 #COL06,
  .page-5 #COL07 {
    min-width: 115px; }
  .page-5 .ate-Hero {
    background-image: url("../images/developer-bg.jpg");
    padding: 24px 0; }
  .page-5 .ate-SearchBar {
    max-width: 990px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 2px; }

.page-6 {
  /* Search bar */
  /* Align buttons + form items in Search Toolbar */
  /* New CSS */ }
  .page-6 .clear-search {
    height: 3.2rem; }
  .page-6 .t-Comments-userIcon {
    height: 48px;
    width: 48px; }
  .page-6 .t-Comments-userIcon img {
    height: 48px; }
  .page-6 #upcoming-events-reg .dates {
    display: block;
    font-size: 1.1rem;
    color: #636363; }
  .page-6 span.month {
    display: block;
    font-size: 10px;
    font-weight: bold;
    line-height: 20px;
    background-color: #DA1B1B;
    border-radius: 2px 2px 0 0;
    text-transform: uppercase; }
  .page-6 span.day {
    display: block;
    line-height: 20px;
    padding: 3px 0 5px;
    background-color: #FFF;
    color: #404040;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
    border-radius: 0 0 2px 2px;
    font-size: 16px;
    font-weight: normal; }
  .page-6 .t-Cards--basic .t-Card .t-Card-icon .t-Icon {
    background-color: transparent; }
  .page-6 .t-Region.t-Region--noUI .t-Region-body {
    padding: 12px; }
  .page-6 .ate-Messages {
    list-style-type: none;
    margin: 0; }
  .page-6 .ate-Messages li {
    border-bottom: 1px solid #F0F0F0;
    padding-bottom: 12px;
    margin-bottom: 12px; }
  .page-6 .ate-Messages li:last-child {
    margin-bottom: 0;
    border-bottom-width: 0; }
  .page-6 .ate-Messages-imgLink {
    display: block; }
  .page-6 .ate-Messages-img {
    display: block;
    max-width: 100%;
    margin: 0 auto; }
  .page-6 .ate-Messages p {
    margin: 0 1.2rem 0 0; }
  .page-6 .u-Processing {
    top: 20px !important; }
  .page-6 .ate-Experts {
    margin: 0;
    list-style: none;
    padding: 0; }
  .page-6 .ate-Experts li {
    display: block;
    overflow: hidden;
    padding: 12px;
    border-bottom: 1px solid #F0F0F0; }
  .page-6 .ate-Experts li:last-child {
    border-bottom-width: 0; }
  .page-6 .ate-Expert-photo {
    float: left;
    margin-right: 8px; }
  .page-6 .ate-Expert-photo img {
    border-radius: 6px;
    width: 64px;
    height: 64px; }
  .page-6 .ate-Expert-info {
    overflow: hidden; }
  .page-6 .ate-Expert-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    display: block; }
  .page-6 .ate-Expert-social {
    font-size: 11px;
    line-height: 16px;
    display: block; }
  .page-6 .ate-Expert-socialIcon {
    font-size: 14px;
    display: inline-block;
    vertical-align: top; }
  .page-6 .ate-Expert-socialIcon.fa-twitter {
    color: #00ACED; }
  .page-6 .ate-Expert-socialIcon.fa-youtube-play {
    color: #CE1312; }
  .page-6 .ate-Expert-bio {
    margin: 8px 0;
    font-size: 12px;
    line-height: 16px; }
  .page-6 .ate-Expert-learnMore {
    font-size: 11px;
    line-height: 16px; }
  .page-6 .ate-Expert-status {
    font-size: 11px;
    color: #707070;
    display: block;
    line-height: 16px; }
  .page-6 .ate-Experts--full .ate-Expert-bio {
    font-size: 14px;
    line-height: 20px; }
  .page-6 .ate-Experts--full .ate-Expert-profile p {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 8px 0; }
  .page-6 .ate-Experts--full .ate-Expert-profile p:last-child {
    margin-bottom: 0; }
  .page-6 .ate-Experts--full li {
    padding: 16px; }
  .page-6 .ate-Experts--full .ate-Expert-name {
    font-size: 16px; }
  .page-6 .ate-Experts--full .ate-Expert-social,
  .page-6 .ate-Experts--full .ate-Expert-status {
    font-size: 12px;
    margin-top: 8px; }
  .page-6 #upcoming-events-reg .t-Card-info {
    white-space: normal;
    overflow: visible; }
  .page-6 .ate-SearchBar .u-Processing {
    margin-top: 4px; }
  .page-6 .ate-SearchBar input[type=text].t-Form-searchField {
    width: 280px; }
  @media only screen and (max-width: 640px) {
    .page-6 .ate-SearchBar input[type=text].t-Form-searchField {
      width: 50%; }
    .page-6 .ate-SearchBar.t-Form--floatLeft .col {
      float: left;
      /* width: 100% !important; */ }
    .page-6 .ate-SearchBar .t-ButtonRegion-col {
      display: block;
      width: auto; }
    .page-6 .ate-SearchBar .t-ButtonRegion-col--right .t-ButtonRegion-buttons {
      text-align: left;
      padding: 0 16px 16px 16px; }
    .page-6 .ate-SearchBar .t-ButtonRegion-col--right .t-ButtonRegion-buttons .t-Button {
      display: block;
      width: 100%;
      font-weight: bold; }
    .page-6 div#P1_SEARCH_CRITERIA_CONTAINER {
      display: inline-block;
      vertical-align: top;
      margin-top: 0; } }
  @media only screen and (max-width: 768px) {
    .page-6 .ate-SearchBar input[type=text].t-Form-searchField {
      width: 180px; } }
  .page-6 .ate-SearchBar-input .t-Form-inputContainer {
    position: relative; }
  .page-6 button#clear-btn {
    position: absolute;
    right: 8px;
    display: none; }
  .page-6 .ate-SearchBar input[type=text].t-Form-searchField {
    padding-right: 32px; }
  .page-6 #COL06,
  .page-6 #COL07 {
    min-width: 115px; }
  .page-6 .ate-Hero {
    background-image: url("../images/developer-bg.jpg");
    padding: 24px 0; }
  .page-6 .ate-SearchBar {
    max-width: 990px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 2px; }

.page-9 {
  /* Orange Header CSS */ }
  .page-9 #favorite-links-reg,
  .page-9 #favorite-links-reg .t-Region-header,
  .page-9 #favorite-links-reg .t-Region-body,
  .page-9 #recommended-books-reg,
  .page-9 #recommended-books-reg .t-Region-header,
  .page-9 #recommended-books-reg .t-Region-body,
  .page-9 #presentation-downloads-reg,
  .page-9 #presentation-downloads-reg .t-Region-header,
  .page-9 #presentation-downloads-reg .t-Region-body {
    background: transparent;
    background-color: transparent; }
  .page-9 #recommended-books-reg .t-Card-titleWrap {
    padding: 12px 12px 12px 16px; }
  .page-9 #recommended-books-reg .t-Card-desc {
    text-align: center; }
  .page-9 .a-IRR-search-field {
    border-color: #E0E0E0;
    box-shadow: -1px 0 0 #E0E0E0 inset, 1px 0 0 #E0E0E0 inset; }
  .page-9 .ate-Hero {
    background-image: url("../images/developer-bg.jpg");
    padding: 24px 0; }

.page-10 .search-results-desc {
  margin: 10px 0 5px 10px; }

.page-11 {
  /* Other Resources */
  /* Announcement Region */ }
  .page-11 .ate-Question-followup {
    transition: all .2s; }
  .page-11 .ate-Question-followup.flash {
    background-color: yellow; }
  .page-11 .ate-Question-banner-message {
    padding: 5px;
    display: inline-block;
    background-color: #FFFFE1;
    border: 1px solid #E0E091;
    border-radius: 2px;
    margin-top: 5px !important; }
  .page-11 .ate-Question-banner-message > .fa {
    margin-right: 3px; }
  .page-11 .resource-Img {
    width: 41px;
    height: 41px;
    padding-bottom: 12px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%; }
  .page-11 .livesql-Img {
    background-image: url(../images/livesql.png); }
  .page-11 .devgym-Img {
    background-image: url(../images/dev-gym.png); }
  .page-11 .resource-Desc h5 {
    margin-bottom: 3px;
    margin-top: 4px; }
  .page-11 .resource-Desc p {
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    margin-bottom: 13px;
    line-height: 1.4; }
  .page-11 .announce-Reg .t-Region-headerItems.t-Region-headerItems--title h2 {
    font-size: 20px;
    font-weight: 600;
    padding-left: 6px; }
  .page-11 .announce-Reg .t-Region-headerIcon {
    border-radius: 50px;
    background-color: dodgerblue;
    color: white;
    font-weight: bold; }

.page-12 {
  /*
 * The following is used to better allign the post-text in P12_REVIEW_FORMATTER
 */ }
  .page-12 .fa,
  .page-12 .fa:after {
    line-height: 2.4rem; }
  .page-12 h3 {
    color: #737373 !important; }
  .page-12 .formatter-pre-text {
    margin: 0 0 5px 5px; }

.page-14 {
  /* Search bar */
  /* Align buttons + form items in Search Toolbar */
  /* New CSS */ }
  .page-14 .clear-search {
    height: 3.2rem; }
  .page-14 .t-Comments-userIcon {
    height: 48px;
    width: 48px; }
  .page-14 .t-Comments-userIcon img {
    height: 48px; }
  .page-14 #upcoming-events-reg .dates {
    display: block;
    font-size: 1.1rem;
    color: #636363; }
  .page-14 span.month {
    display: block;
    font-size: 10px;
    font-weight: bold;
    line-height: 20px;
    background-color: #DA1B1B;
    border-radius: 2px 2px 0 0;
    text-transform: uppercase; }
  .page-14 span.day {
    display: block;
    line-height: 20px;
    padding: 3px 0 5px;
    background-color: #FFF;
    color: #404040;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
    border-radius: 0 0 2px 2px;
    font-size: 16px;
    font-weight: normal; }
  .page-14 .t-Cards--basic .t-Card .t-Card-icon .t-Icon {
    background-color: transparent; }
  .page-14 .t-Region.t-Region--noUI .t-Region-body {
    padding: 12px; }
  .page-14 .ate-Messages {
    list-style-type: none;
    margin: 0; }
  .page-14 .ate-Messages li {
    border-bottom: 1px solid #F0F0F0;
    padding-bottom: 12px;
    margin-bottom: 12px; }
  .page-14 .ate-Messages li:last-child {
    margin-bottom: 0;
    border-bottom-width: 0; }
  .page-14 .ate-Messages-imgLink {
    display: block; }
  .page-14 .ate-Messages-img {
    display: block;
    max-width: 100%;
    margin: 0 auto; }
  .page-14 .ate-Messages p {
    margin: 0 1.2rem 0 0; }
  .page-14 .u-Processing {
    top: 20px !important; }
  .page-14 .ate-Experts {
    margin: 0;
    list-style: none;
    padding: 0; }
  .page-14 .ate-Experts li {
    display: block;
    overflow: hidden;
    padding: 12px;
    border-bottom: 1px solid #F0F0F0; }
  .page-14 .ate-Experts li:last-child {
    border-bottom-width: 0; }
  .page-14 .ate-Expert-photo {
    float: left;
    margin-right: 8px; }
  .page-14 .ate-Expert-photo img {
    border-radius: 6px;
    width: 64px;
    height: 64px; }
  .page-14 .ate-Expert-info {
    overflow: hidden; }
  .page-14 .ate-Expert-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    display: block; }
  .page-14 .ate-Expert-social {
    font-size: 11px;
    line-height: 16px;
    display: block; }
  .page-14 .ate-Expert-socialIcon {
    font-size: 14px;
    display: inline-block;
    vertical-align: top; }
  .page-14 .ate-Expert-socialIcon.fa-twitter {
    color: #00ACED; }
  .page-14 .ate-Expert-socialIcon.fa-youtube-play {
    color: #CE1312; }
  .page-14 .ate-Expert-bio {
    margin: 8px 0;
    font-size: 12px;
    line-height: 16px; }
  .page-14 .ate-Expert-learnMore {
    font-size: 11px;
    line-height: 16px; }
  .page-14 .ate-Expert-status {
    font-size: 11px;
    color: #707070;
    display: block;
    line-height: 16px; }
  .page-14 .ate-Experts--full .ate-Expert-bio {
    font-size: 14px;
    line-height: 20px; }
  .page-14 .ate-Experts--full .ate-Expert-profile p {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 8px 0; }
  .page-14 .ate-Experts--full .ate-Expert-profile p:last-child {
    margin-bottom: 0; }
  .page-14 .ate-Experts--full li {
    padding: 16px; }
  .page-14 .ate-Experts--full .ate-Expert-name {
    font-size: 16px; }
  .page-14 .ate-Experts--full .ate-Expert-social,
  .page-14 .ate-Experts--full .ate-Expert-status {
    font-size: 12px;
    margin-top: 8px; }
  .page-14 #upcoming-events-reg .t-Card-info {
    white-space: normal;
    overflow: visible; }
  .page-14 .ate-SearchBar .u-Processing {
    margin-top: 4px; }
  .page-14 .ate-SearchBar input[type=text].t-Form-searchField {
    width: 280px; }
  @media only screen and (max-width: 640px) {
    .page-14 .ate-SearchBar input[type=text].t-Form-searchField {
      width: 50%; }
    .page-14 .ate-SearchBar.t-Form--floatLeft .col {
      float: left;
      /* width: 100% !important; */ }
    .page-14 .ate-SearchBar .t-ButtonRegion-col {
      display: block;
      width: auto; }
    .page-14 .ate-SearchBar .t-ButtonRegion-col--right .t-ButtonRegion-buttons {
      text-align: left;
      padding: 0 16px 16px 16px; }
    .page-14 .ate-SearchBar .t-ButtonRegion-col--right .t-ButtonRegion-buttons .t-Button {
      display: block;
      width: 100%;
      font-weight: bold; }
    .page-14 div#P1_SEARCH_CRITERIA_CONTAINER {
      display: inline-block;
      vertical-align: top;
      margin-top: 0; } }
  @media only screen and (max-width: 768px) {
    .page-14 .ate-SearchBar input[type=text].t-Form-searchField {
      width: 180px; } }
  .page-14 .ate-SearchBar-input .t-Form-inputContainer {
    position: relative; }
  .page-14 button#clear-btn {
    position: absolute;
    right: 8px;
    display: none; }
  .page-14 .ate-SearchBar input[type=text].t-Form-searchField {
    padding-right: 32px; }
  .page-14 #COL06,
  .page-14 #COL07 {
    min-width: 115px; }
  .page-14 .ate-Hero {
    background-image: url("../images/developer-bg.jpg");
    padding: 24px 0; }
  .page-14 .ate-SearchBar {
    max-width: 990px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 2px; }

.page-23 {
  /* Orange Header CSS */ }
  .page-23 .asktom-logo {
    background-image: url(../images/logo180x180.png);
    display: block;
    height: 180px;
    width: 180px;
    margin: 24px auto;
    background-repeat: no-repeat; }
  .page-23 .ate-Summary-list {
    list-style: none;
    margin: 8px 0 0 0;
    padding: 0; }
  .page-23 .ate-Summary-list li {
    border-top: 1px solid #F0F0F0;
    padding: 8px 0;
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .page-23 .ate-Summary-list strong {
    display: inline-block;
    width: 35%;
    text-align: right;
    border-radius: 2px;
    margin-right: 16px;
    font-size: 20px;
    font-weight: normal;
    line-height: 32px;
    vertical-align: top;
    color: #8DA6B1; }
  .page-23 .ate-Summary-title {
    font-size: 16px;
    line-height: 24px;
    display: block;
    text-align: left;
    margin: 12px;
    font-weight: normal; }
  .page-23 .ate-About p {
    margin: 0 0 1.6rem 0; }
  .page-23 .ate-About p:first-child {
    font-size: 18px;
    line-height: 24px; }
  .page-23 .ate-About p:last-child {
    margin-bottom: 0; }
  .page-23 .ate-About.t-Region .t-Region-body {
    padding: 16px; }
  .page-23 .ate-Experts {
    margin: 0;
    list-style: none;
    padding: 0; }
  .page-23 .ate-Experts li {
    display: block;
    overflow: hidden;
    padding: 12px;
    border-bottom: 1px solid #F0F0F0; }
  .page-23 .ate-Experts li:last-child {
    border-bottom-width: 0; }
  .page-23 .ate-Expert-photo {
    float: left;
    margin-right: 8px; }
  .page-23 .ate-Expert-photo img {
    border-radius: 6px;
    width: 64px;
    height: 64px; }
  .page-23 .ate-Expert-info {
    overflow: hidden; }
  .page-23 .ate-Expert-name {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    display: block; }
  .page-23 .ate-Expert-social {
    font-size: 11px;
    line-height: 16px;
    display: block; }
  .page-23 .ate-Expert-socialIcon {
    font-size: 14px;
    display: inline-block;
    vertical-align: top; }
  .page-23 .ate-Expert-socialIcon.fa-twitter {
    color: #00ACED; }
  .page-23 .ate-Expert-socialIcon.fa-youtube-play {
    color: #CE1312; }
  .page-23 .ate-Expert-bio {
    margin: 8px 0;
    font-size: 12px;
    line-height: 16px; }
  .page-23 .ate-Expert-learnMore {
    font-size: 11px;
    line-height: 16px; }
  .page-23 .ate-Expert-status {
    font-size: 11px;
    color: #707070;
    display: block;
    line-height: 16px; }
  .page-23 .ate-Experts--full .ate-Expert-bio {
    font-size: 14px;
    line-height: 20px; }
  .page-23 .ate-Experts--full .ate-Expert-profile p {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 8px 0; }
  .page-23 .ate-Experts--full .ate-Expert-profile p:last-child {
    margin-bottom: 0; }
  .page-23 .ate-Experts--full li {
    padding: 16px; }
  .page-23 .ate-Experts--full .ate-Expert-name {
    font-size: 16px; }
  .page-23 .ate-Experts--full .ate-Expert-social,
  .page-23 .ate-Experts--full .ate-Expert-status {
    font-size: 12px;
    margin-top: 8px; }
  .page-23 hr {
    border-color: #F0F0F0;
    border-style: solid;
    border-width: 1px 0 0 0;
    padding-top: 8px;
    margin-top: 16px;
    display: block; }
  .page-23 .ate-Hero {
    background-image: url("../images/developer-bg.jpg");
    padding: 24px 0; }

.page-60 button#go_butn {
  position: relative;
  top: 8px;
  left: -121px; }

.page-60 .col-6 > .rel-col .col-2 {
  width: 14.33333%; }

.page-72 #supp_files_imagery img {
  width: 200px; }

.page-81 .delete-photo {
  margin-left: 10px;
  display: none; }

.page-99 .t-BadgeList--dash .t-BadgeList-item {
  padding: 0; }

.page-99 .t-BadgeList--dash .t-BadgeList-wrap {
  flex-direction: row;
  align-items: center; }

.page-99 .t-BadgeList--dash .t-BadgeList-value {
  flex-grow: 0;
  order: 2;
  text-align: right;
  width: auto;
  font-size: 1.4rem;
  flex-shrink: 0; }

.page-99 .t-BadgeList--dash .t-BadgeList-label {
  flex-grow: 1;
  width: 100%;
  font-size: 1.4rem;
  text-align: left;
  order: 1; }

.page-99 .t-BadgeList--dash a.t-BadgeList-wrap:hover:before {
  content: none; }

.page-99 .t-BadgeList--dash .t-BadgeList-wrap:hover {
  background: #F0F0F0; }

.page-201 pre {
  background-color: #F8F8F8;
  padding: 12px;
  font-size: 11px;
  float: left;
  border-radius: 2px;
  margin: 0;
  overflow: auto;
  z-index: 1;
  clear: left;
  line-height: 14.5px; }

.page-201 .apex-item-display-only {
  min-height: 2.4rem;
  box-shadow: none;
  font-weight: 400;
  line-height: 24px; }

.page-230 {
  /*
.fu-edit {
    background-color: #FAEAD7;
}

.fu-add {
    background-color: #F0FFF3;
}
*/ }

.page-500 {
  /* Hero Hader Styles */
  /* Ask Tom Homepage Styles */
  /* Old Styles */
  /* Card Styles */
  /* Report styles */
  /* no data found text */
  /* oh report buttons */ }
  .page-500 #P500_PRODUCTS {
    height: 35px; }
  .page-500 #P500_SCHEDULED {
    height: 35px; }
  .page-500 #P500_MY_OH {
    height: 35px; }
  .page-500 .col.col-2.oh-Buttons {
    top: 30px; }
  .page-500 .your-Status {
    text-align: center; }
  .page-500 .col-12.filter-Region {
    padding-bottom: 10px;
    margin-bottom: 10px; }
  .page-500 .attending {
    color: limegreen; }
  .page-500 .teacher-Name {
    font-size: 15px; }
  .page-500 .start-Date {
    font-size: 16px;
    font-weight: 600; }
  .page-500 .start-Time {
    font-size: 15px;
    position: relative;
    top: -21px; }
  .page-500 .t-Card-body {
    margin-top: 0 !important; }
  .page-500 .t-Card-titleWrap {
    margin-bottom: 0 !important; }
  .page-500 #P500_MY_OH .a-Button {
    padding: 10px !important; }
  .page-500 #archive-But {
    top: 30px; }
  .page-500 .col.col-2.select-OH {
    width: 147px; }
  .page-500 .oh-Buttons {
    float: right; }
  .page-500 .starts-In {
    font-size: 14px;
    position: relative;
    top: -21px; }
  .page-500 .ate-Hero {
    background-image: url("../images/developer-bg.jpg"); }
  .page-500 .ate-Hero {
    padding: 24px 0 !important; }
  .page-500 .ate-Hero-block .t-Form-fieldContainer input#P500_SEARCH {
    padding: 20px;
    height: 64px;
    background: #FFF;
    font-size: 24px;
    line-height: 24px; }
  .page-500 .ate-Hero {
    background-image: url("../images/developer-bg.jpg"); }
  .page-500 .your-Status {
    text-align: center; }
  .page-500 .fa.fa-check {
    font-weight: bold;
    color: limegreen; }
  .page-500 .attending {
    color: limegreen; }
  .page-500 .teacher-Name {
    font-size: 15px; }
  .page-500 .start-Date {
    font-size: 16px;
    font-weight: 600; }
  .page-500 .start-Time {
    font-size: 15px;
    position: relative;
    top: -21px; }
  .page-500 .t-Card-body {
    margin-top: 0 !important; }
  .page-500 .t-Card-titleWrap {
    margin-bottom: 0 !important; }
  .page-500 .t-Cards.cards-office-hours .t-Card-body {
    text-align: center;
    padding-top: 9px;
    padding-bottom: 15px;
    padding-left: 16px;
    padding-right: 16px;
    border-top-width: 0; }
  .page-500 .class-name {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    height: 48px; }
  .page-500 .class-date-time,
  .page-500 .every-Month {
    font-size: 12px;
    color: #707070;
    margin-top: 8px; }
  .page-500 .t-Cards.cards-office-hours .t-Card-icon {
    margin-top: 16px; }
  .page-500 .t-Cards.cards-office-hours .t-Card-titleWrap {
    padding: 16px 16px 0 16px; }
  .page-500 .t-Cards.cards-office-hours .t-Card-title {
    font-size: 14px;
    line-height: 20px; }
  .page-500 .report-Img {
    height: 55px;
    width: 55px;
    border-radius: 100px;
    top: 2px;
    left: -9px;
    position: relative; }
  .page-500 .date-Desc {
    padding-right: 46px;
    color: rgba(0, 0, 0, 0.55); }
  .page-500 span.oh-Sub-text {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.55);
    position: relative;
    top: 2px; }
  .page-500 span.oh-Sub-text2 {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.55);
    position: relative;
    top: -11px;
    left: 63px; }
  .page-500 span.oh-Prime-text {
    position: relative;
    top: 17px;
    left: 18px; }
  .page-500 .oh-Links-text {
    font-size: 12px; }
  .page-500 .oh-Title-text {
    font-weight: 500; }
  .page-500 .second-Link {
    margin-left: 14px; }
  .page-500 .t-Report-cell {
    background-color: white !important; }
  .page-500 .t-Report-wrap {
    float: none !important; }
  .page-500 table.t-Report-report {
    margin: 0 auto; }
  .page-500 td.t-Report-cell {
    font-size: 15px; }
  .page-500 a.title-Link {
    font-weight: bold; }
  .page-500 .officeh-Body {
    font-size: 1.5rem; }
  .page-500 .officeh-Body2 {
    font-size: 1.5rem; }
  .page-500 .ndf-Text {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500; }
  .page-500 a.oh-Button {
    border: 1px solid #ededed;
    padding: 8px;
    border-radius: 4px;
    text-align: center; }
  .page-500 a.oh-Button.second-Link {
    min-width: 137px; }
  .page-500 a.oh-Button.cal-Button {
    margin-right: 12px;
    margin-left: 11px; }
  .page-500 a.oh-Button:hover {
    background-color: #2370c2;
    color: white; }
  .page-500 a.oh-Button.signed-Up:hover {
    background-color: #c74534;
    color: white; }
  .page-500 a.oh-Button:hover .fa.fa-check-circle {
    color: white;
    background-color: #2370c2; }
  .page-500 a.oh-Button:hover.signed-Up .fa.fa-check-circle {
    color: white;
    background-color: #c74534; }
  .page-500 a.oh-Button.second-Link.widen-Button {
    padding: 8px 23px; }
  .page-500 .fa.fa-check-circle {
    color: #2370c2;
    background-color: white;
    padding-top: 2px;
    padding-left: 5px; }
  .page-500 .dg-ContentRowReport-itemMisc {
    padding-top: 3px;
    font-size: 13px; }
  .page-500 a.oh-Button.cal-Button {
    margin-right: 12px;
    margin-left: 11px; }
  .page-500 .series-Name {
    color: rgba(0, 0, 0, 0.55); }
  .page-500 span.fa.fa-calendar-plus-o {
    position: relative;
    top: 2px; }
  .page-500 p.content-intro {
    margin-top: 11px; }
  .page-500 span.fa.fa-plus-circle-o {
    padding-top: 2px;
    padding-left: 5px; }

.page-503 .t-Region-headerItems--title {
  text-align: center;
  font-size: 25px; }

.page-503 .author-Image {
  border-radius: 100px;
  height: 64px;
  position: relative;
  left: 9px; }

.page-503 .t-Report-cell {
  font-size: 15px;
  padding: 24px; }

.page-503 .nameand-Time {
  position: relative;
  top: -12px; }

.page-503 .t-Report-wrap {
  width: 1196px; }

.page-503 .t-Report-report {
  margin: 0 auto; }

.page-503 .author-Face {
  text-align: center; }

.page-504 {
  /* center email notification*/
  /* Styles for different button arrangements */
  /* Social options alignment */ }
  .page-504 .question-Div {
    padding: 10px;
    font-size: 17px;
    font-weight: 600; }
  .page-504 .answer-Div {
    padding: 10px;
    font-size: 17px; }
  .page-504 #class-Info p.t-MediaList-desc {
    font-size: 17px !important;
    font-weight: 600; }
  .page-504 h1.t-ContentBlock-title {
    text-align: center; }
  .page-504 .center-Div {
    text-align: center;
    font-size: 17px; }
  .page-504 .left-Div {
    text-align: left;
    font-size: 17px; }
  .page-504 .right-Div {
    text-align: right;
    font-size: 17px; }
  .page-504 .t-Button--large {
    padding: 19px;
    font-size: 16px; }
  .page-504 .center-Div.date-Text {
    font-size: 15px; }
  .page-504 #P29_SEND_EMAILS_CONTAINER .t-Form-itemWrapper {
    position: relative;
    left: 452px; }
  .page-504 label#P29_RATING_LABEL {
    padding: 0;
    line-height: 0.6rem; }
  .page-504 label#P29_SEND_EMAILS_LABEL {
    position: relative;
    left: 431px; }
  .page-504 .t-MediaList--horizontal .t-MediaList-item {
    border-right: 0 !important; }
  .page-504 .t-Form-labelContainer.col.col-3 {
    width: 40% !important; }
  .page-504 .t-Form-inputContainer.col.col-7 {
    width: 60% !important; }
  .page-504 div#class-Info {
    background-color: #fafafa; }
  .page-504 p.t-MediaList-desc {
    padding-bottom: 1em; }
  .page-504 td span.fa.fa-star {
    color: #F5CD00; }
  .page-504 td span.fa.fa-star-half-o {
    color: #F5CD00; }
  .page-504 p.t-MediaList-desc .fa-star {
    color: #F5CD00;
    position: relative;
    top: 6px; }
  .page-504 p.t-MediaList-desc .fa-star-o {
    position: relative;
    top: 6px; }
  .page-504 p.t-MediaList-desc .fa-star-half-o {
    color: #F5CD00;
    position: relative;
    top: 6px; }
  .page-504 .t-Region-body {
    padding: 0 !important; }
  .page-504 .is-Red-text a {
    color: #da1b1b; }
  .page-504 #full-Credit {
    font-size: 17px; }
  .page-504 .fa-check {
    font-size: 20px;
    padding-left: 6px;
    font-weight: bold; }
  .page-504 .t-Report-wrap {
    float: none !important; }
  .page-504 .t-Report-report {
    margin-left: auto;
    margin-right: auto; }
  .page-504 #outline-Survey .t-Region-buttons-left {
    margin-left: 32.5%; }
  .page-504 #cert-Survey-parent .t-Region-buttons-left {
    margin-left: 33.8%; }
  .page-504 #child-Cert-survey .t-Region-buttons-left {
    margin-left: 24.8%; }
  .page-504 #only-Survey-parent .t-Region-buttons-left {
    margin-left: 41.7%; }
  .page-504 .back-To-parent {
    margin-left: -23%; }
  .page-504 .register {
    margin-top: 21px;
    position: relative;
    left: 40px; }
  .page-504 #all-Fb {
    position: relative;
    top: -4px; }
  .page-504 .social-Options {
    margin-left: 432px;
    padding-bottom: 13px; }
  .page-504 .fa-check {
    line-height: 13px;
    color: limegreen; }
  .page-504 .register {
    margin-left: 398px; }

.page-506 #class-Header {
  font-size: 32px;
  color: dodgerblue;
  padding-bottom: 5px; }

.page-506 .t-Form-inputContainer fieldset {
  position: relative;
  left: 339px; }

.page-506 #class-Start-header {
  padding-top: 7px;
  padding-left: 2px; }

.page-506 .class-Header-region {
  text-align: center;
  /* border-bottom: 1px solid #E0E0E0; */ }

.page-506 .t-Dialog .col-12 {
  padding-top: 10px; }

.page-506 .t-Dialog .col-12.last-Region {
  border-bottom: none; }

.page-506 .officeh-Header2 {
  font-size: 21px;
  padding-bottom: 15px;
  padding-top: 3px; }

.page-506 .officeh-Image {
  float: left;
  padding-right: 13px;
  padding-top: 21px; }

.page-506 .officeh-Image img {
  border-radius: 30px; }

.page-506 .officeh-Image2 {
  float: left;
  padding-right: 13px;
  clear: both; }

.page-506 .officeh-Body2 {
  padding-top: 36px; }

.page-506 .officeh-Image2 img {
  border-radius: 30px; }

.page-506 .officeh-Image3 {
  float: left;
  padding-right: 13px;
  clear: both; }

.page-506 .officeh-Body3 {
  padding-top: 36px; }

.page-506 .officeh-Image3 img {
  border-radius: 30px; }

.page-506 .officeh-Spacer {
  position: relative;
  left: 79px;
  /*  border-top: 1px solid #E0E0E0; */
  padding-top: 13px;
  margin-right: 94px; }

.page-506 .t-Dialog-body {
  padding: 0; }

.page-506 .t-Region .t-Region-body {
  padding-left: 12px !important; }

.page-506 .sendMailDiv {
  padding-top: 18px; }

.page-506 .sendMail {
  background-color: dodgerblue;
  color: white;
  padding: 9px; }

.page-506 .col-12 > .rel-col .col-2 {
  width: 9.667%; }

.page-506 .t-Card-icon img {
  width: 64px;
  height: 64px;
  display: block;
  border-radius: 100%; }

.page-508 {
  /* Report styles */
  /* Orange Header CSS */ }
  .page-508 .report-Img {
    height: 54px;
    width: 54px;
    border-radius: 100px; }
  .page-508 .ate-Hero {
    background-image: url("../images/developer-bg.jpg");
    padding: 24px 0; }

.page-551 {
  /*
#class-Header {
    font-size: 28px;
    color: dodgerblue;

padding-bottom: 5px;
}

  #class-Start-header {
    padding-top: 3px;
    padding-left: 2px;
    font-size: 1.5rem; 
    padding-bottom: 3px;
  }
 
.class-Header-region{

    border-bottom: 1px solid #E0E0E0;
}
*/
  /* Header CSS */
  /* star rating leveling with label */
  /* twitter follow button */
  /* Q & A styling */
  /* Miscellaneous */
  /* start times around the world modal */
  /* announcement text styles*/
  /*To fix no label that still has label issue*/ }
  .page-551 #officeh-Header {
    font-size: 22px; }
  .page-551 .t-Dialog .col-12 {
    padding-top: 10px; }
  .page-551 .t-Dialog .col-12.last-Region {
    border-bottom: none; }
  .page-551 .t-ContentBlock-header {
    margin-bottom: 0px !important; }
  .page-551 .t-ContentBlock-title {
    margin-bottom: 7px; }
  .page-551 .officeh-Header2 {
    font-size: 21px;
    padding-top: 3px; }
  .page-551 .officeh-Sub-header {
    font-size: 15px; }
  .page-551 .officeh-Sub-header h2 {
    float: left; }
  .page-551 .officeh-Sub-header p {
    position: relative;
    top: 10px;
    left: 12px; }
  .page-551 .officeh-Header21 {
    font-size: 21px;
    padding-top: 3px;
    padding-bottom: 6px; }
  .page-551 .officeh-Header3 {
    font-size: 19px;
    padding-bottom: 12px;
    padding-top: 19px; }
  .page-551 .officeh-Header4 {
    font-size: 19px;
    padding-bottom: 12px; }
  .page-551 .officeh-Expert {
    min-height: 72px;
    margin-bottom: 12px;
    margin-top: 8px; }
  .page-551 .officeh-Image {
    float: left;
    clear: both;
    width: 79px; }
  .page-551 .officeh-Image img {
    border-radius: 30px;
    max-width: 65px;
    height: 64px; }
  .page-551 .officeh-Image2 {
    padding-top: 12px;
    padding-right: 13px;
    clear: both; }
  .page-551 .officeh-Image2 img {
    border-radius: 30px; }
  .page-551 .officeh-Image3 {
    padding-top: 12px;
    padding-right: 13px;
    clear: both; }
  .page-551 .officeh-Image3 img {
    border-radius: 30px; }
  .page-551 button#join-Session {
    margin-top: 14px;
    margin-bottom: 12px; }
  .page-551 .officeh-Info {
    font-weight: 600;
    font-size: 1.5rem; }
  .page-551 .t-Dialog-body {
    padding: 0; }
  .page-551 .sendMailDiv {
    padding-top: 18px; }
  .page-551 .sendMail {
    background-color: dodgerblue;
    color: white;
    padding: 9px; }
  .page-551 .col-12 > .rel-col .col-2 {
    width: 9.667%; }
  .page-551 td.t-Report-cell {
    padding-left: 0 !important; }
  .page-551 .boldText {
    font-weight: 700; }
  .page-551 .ate-Hero {
    background-image: url("../images/developer-bg.jpg");
    padding: 24px 0; }
  .page-551 div#feedback {
    padding-top: 7px;
    margin-top: 13px;
    margin-bottom: 13px; }
  .page-551 #embed-Vid {
    padding-top: 7px;
    margin-top: 13px;
    margin-bottom: 13px; }
  .page-551 #ask-Aquestion,
  .page-551 #post-Session {
    padding-bottom: 16px; }
  .page-551 .officeh-Body2 li {
    font-size: 18px; }
  .page-551 .officeh-Body {
    padding-top: 6px;
    font-size: 1.3rem; }
  .page-551 .submit-Button {
    margin-top: 12px; }
  .page-551 .anchor-Text {
    font-size: 1.5rem; }
  .page-551 .todays-Sesh {
    padding-bottom: 12px; }
  .page-551 .top-Pad {
    padding-top: 19px; }
  .page-551 .t-Form-inputContainer,
  .page-551 .t-Form-labelContainer {
    padding-top: 0; }
  .page-551 .col-12 > .rel-col .col-10 {
    top: 4px;
    left: -6px; }
  .page-551 .twitter-follow-button {
    position: relative;
    height: 20px;
    box-sizing: border-box;
    padding: 1px 8px 1px 6px;
    background-color: #1b95e0;
    color: #fff;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer; }
  .page-551 .t-Report-cell {
    font-size: 1.5rem; }
  .page-551 .bookmark-Link {
    margin-top: 6px;
    font-size: 12px; }
  .page-551 .q-Letter, .page-551 .a-Letter {
    font-size: 16px;
    padding-bottom: 9px;
    font-weight: 600; }
  .page-551 .q-Text, .page-551 .a-Text {
    padding-bottom: 11px; }
  .page-551 .a-Div {
    margin-bottom: 12px; }
  .page-551 #answers .t-ContentBlock-body {
    padding-bottom: 0px; }
  .page-551 .qa-Row {
    padding: 13px;
    margin-bottom: 18px;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden; }
  .page-551 .t-ButtonRegion-col.t-ButtonRegion-col--content {
    padding-left: 0px;
    padding-bottom: 0px; }
  .page-551 .hide-Image {
    display: none; }
  .page-551 #report_sessions td.t-Report-cell:first-child {
    width: 141px; }
  .page-551 #time-zones-reg table {
    width: 100%;
    margin: 0px auto; }
  .page-551 #time-zones-reg table td:nth-child(1) {
    width: 50%; }
  .page-551 #time-zones-reg table td:nth-child(2) {
    width: 20%;
    text-align: right; }
  .page-551 #time-zones-reg table td:nth-child(3) {
    text-align: right; }
  .page-551 #your_time_zone .t-ContentBlock-body, .page-551 #report_selected_tz {
    background-color: #d5e1d2; }
  .page-551 h1#your_time_zone_heading {
    display: none; }
  .page-551 .ate-dev-text p {
    padding-top: 12px; }
  .page-551 label#P551_SESSIONS_SELECT_LABEL {
    display: none; }
  @media only screen and (max-width: 786px) {
    .page-551 .t-ButtonRegion-col.t-ButtonRegion-col--content {
      display: table-row; }
    .page-551 a.anchor-Text {
      display: flex; }
    .page-551 span.ate-dev-photo:first-child {
      display: none; }
    .page-551 .ate-dev-text p {
      float: left;
      width: 347px; }
    .page-551 span.ate-dev-photo img {
      width: 351px !important;
      padding-left: 0px !important; } }

.page-552 table {
  width: 100%;
  margin: 0 auto; }

.page-552 #time-zones-reg table td:nth-child(1) {
  width: 50%; }

.page-552 #time-zones-reg table td:nth-child(2) {
  width: 20%;
  text-align: right; }

.page-552 #time-zones-reg table td:nth-child(3) {
  text-align: right; }

.page-554 .ate-Hero {
  background-image: url("../images/developer-bg.jpg");
  padding: 24px 0; }

.page-554 .ndf-Text {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500; }

.page-651 {
  /*
#class-Header {
    font-size: 28px;
    color: dodgerblue;

padding-bottom: 5px;
}
*/
  /*
.class-Header-region{

    border-bottom: 1px solid #E0E0E0;
}
*/
  /*
#rsvp{
  position: relative;
    left: -168px;
}

#login{
    position: relative;
    left: -191px;
}

#withdraw{
    position: relative;
    left: -171px;
}

*/
  /* Orange Header CSS */
  /* list margin lessened */
  /* star rating leveling with label */
  /* twitter follow button */ }
  .page-651 #officeh-Header {
    font-size: 22px; }
  .page-651 #class-Start-header {
    padding-top: 3px;
    padding-left: 2px;
    font-size: 1.5rem;
    color: dimgray;
    padding-bottom: 3px; }
  .page-651 .t-Dialog .col-12 {
    padding-top: 10px; }
  .page-651 .t-Dialog .col-12.last-Region {
    border-bottom: none; }
  .page-651 .officeh-Header2 {
    font-size: 21px;
    padding-bottom: 15px;
    padding-top: 3px; }
  .page-651 .officeh-Header21 {
    font-size: 21px;
    padding-top: 3px; }
  .page-651 .officeh-Header3 {
    font-size: 19px;
    padding-bottom: 12px;
    padding-top: 19px; }
  .page-651 .officeh-Header4 {
    font-size: 19px;
    padding-bottom: 12px; }
  .page-651 .officeh-Image {
    padding-right: 13px; }
  .page-651 .officeh-Image img {
    border-radius: 30px; }
  .page-651 .officeh-Image2 {
    padding-top: 12px;
    padding-right: 13px;
    clear: both; }
  .page-651 .officeh-Image2 img {
    border-radius: 30px; }
  .page-651 .officeh-Image3 {
    padding-top: 12px;
    padding-right: 13px;
    clear: both; }
  .page-651 .officeh-Image3 img {
    border-radius: 30px; }
  .page-651 button#join-Session {
    margin-top: 14px;
    margin-bottom: 12px; }
  .page-651 .officeh-Info {
    padding: 10px 0;
    font-weight: 600;
    font-size: 1.5rem; }
  .page-651 .t-Dialog-body {
    padding: 0; }
  .page-651 .sendMailDiv {
    padding-top: 18px; }
  .page-651 .sendMail {
    background-color: dodgerblue;
    color: white;
    padding: 9px; }
  .page-651 .col-12 > .rel-col .col-2 {
    width: 9.667%; }
  .page-651 td.t-Report-cell {
    padding-left: 0 !important; }
  .page-651 .boldText {
    font-weight: 700; }
  .page-651 .ate-Hero {
    background-image: url("../images/developer-bg.jpg");
    padding: 24px 0; }
  .page-651 div#feedback {
    padding-top: 7px;
    margin-top: 13px;
    margin-bottom: 13px; }
  .page-651 #embed-Vid {
    padding-top: 7px;
    margin-top: 13px;
    margin-bottom: 13px; }
  .page-651 #ask-Aquestion,
  .page-651 #post-Session {
    padding-bottom: 16px; }
  .page-651 ol,
  .page-651 ul {
    margin: 0.2rem 2.2rem; }
  .page-651 .officeh-Body2 li {
    font-size: 18px; }
  .page-651 .officeh-Body {
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 1.5rem; }
  .page-651 .anchor-Text {
    font-size: 1.5rem; }
  .page-651 .todays-Sesh {
    padding-bottom: 12px; }
  .page-651 .top-Pad {
    padding-top: 19px; }
  .page-651 .t-Form-inputContainer,
  .page-651 .t-Form-labelContainer {
    padding-top: 0; }
  .page-651 .col-12 > .rel-col .col-10 {
    top: 4px;
    left: -6px; }
  .page-651 .twitter-follow-button {
    position: relative;
    height: 20px;
    box-sizing: border-box;
    padding: 1px 8px 1px 6px;
    background-color: #1b95e0;
    color: #fff;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer; }
  .page-651 .t-Report-cell {
    font-size: 1.5rem; }

.page-1000 {
  /* Old Styles */
  /* Card Styles */
  /* Smaller hero region  and small screen handling */
  /*
input#ask_q_header {
    padding: 15px;
    font-size: 17px;
    margin-left: 17px;
    position: relative;
    top: 9px;
}
*/
  /*Style header for small screen */ }
  .page-1000 .your-Status {
    text-align: center; }
  .page-1000 .fa.fa-check {
    font-weight: bold;
    color: limegreen; }
  .page-1000 .attending {
    color: limegreen; }
  .page-1000 .teacher-Name {
    font-size: 15px; }
  .page-1000 .start-Date {
    font-size: 16px;
    font-weight: 600; }
  .page-1000 .start-Time {
    font-size: 15px;
    position: relative;
    top: -21px; }
  .page-1000 .t-Card-body {
    margin-top: 0 !important; }
  .page-1000 .t-Card-titleWrap {
    margin-bottom: 0 !important; }
  .page-1000 .t-Card-icon img {
    width: 64px;
    height: 64px;
    display: block;
    border-radius: 100%; }
  .page-1000 .ate-question-link {
    font-size: 14px; }
  .page-1000 .t-Cards.cards-office-hours .t-Card-body {
    text-align: center;
    padding: 16px;
    border-top-width: 0; }
  .page-1000 .class-name {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    height: 48px; }
  .page-1000 .class-date-time,
  .page-1000 .every-Month {
    font-size: 12px;
    color: #707070;
    margin-top: 8px; }
  .page-1000 .t-Cards.cards-office-hours .t-Card-icon {
    margin-top: 16px; }
  .page-1000 .t-Cards.cards-office-hours .t-Card-titleWrap {
    padding: 16px 16px 0 16px; }
  .page-1000 .t-Cards.cards-office-hours .t-Card-title {
    font-size: 14px;
    line-height: 20px; }
  .page-1000 span.fa.fa-database-check {
    font-size: 25px;
    color: rgba(0, 0, 0, 0.6); }
  .page-1000 span.ate-question-preview {
    position: relative;
    top: 5px;
    line-height: 1.5; }
  .page-1000 td.t-Report-cell {
    padding: 16px; }
  .page-1000 .t-Report-cell span.fa {
    position: relative;
    left: 10px;
    top: 4px; }
  .page-1000 input#ask_q_header {
    padding: 12px 24px;
    margin: 12px 0 12px 16px; }
  .page-1000 .t-Report-cell img {
    border-radius: 100%;
    width: 42px;
    height: 42px; }

.page-1551 {
  /*
#class-Header {
    font-size: 28px;
    color: dodgerblue;

padding-bottom: 5px;
}
*/
  /*
.class-Header-region{

    border-bottom: 1px solid #E0E0E0;
}
*/
  /*
#rsvp{
  position: relative;
    left: -168px;
}

#login{
    position: relative;
    left: -191px;
}

#withdraw{
    position: relative;
    left: -171px;
}

*/
  /* Orange Header CSS */
  /* list margin lessened */
  /* star rating leveling with label */ }
  .page-1551 #officeh-Header {
    font-size: 22px; }
  .page-1551 #class-Start-header {
    padding-top: 3px;
    padding-left: 2px;
    font-size: 1.5rem;
    color: dimgray;
    padding-bottom: 3px; }
  .page-1551 .t-Dialog .col-12 {
    padding-top: 10px; }
  .page-1551 .t-Dialog .col-12.last-Region {
    border-bottom: none; }
  .page-1551 .officeh-Header2 {
    font-size: 21px;
    padding-bottom: 15px;
    padding-top: 3px; }
  .page-1551 .officeh-Header21 {
    font-size: 21px;
    padding-top: 3px; }
  .page-1551 .officeh-Header3 {
    font-size: 19px;
    padding-bottom: 12px;
    padding-top: 19px; }
  .page-1551 .officeh-Header4 {
    font-size: 19px;
    padding-bottom: 12px; }
  .page-1551 .officeh-Image {
    padding-right: 13px; }
  .page-1551 .officeh-Image img {
    border-radius: 30px; }
  .page-1551 .officeh-Image2 {
    padding-top: 12px;
    padding-right: 13px;
    clear: both; }
  .page-1551 .officeh-Image2 img {
    border-radius: 30px; }
  .page-1551 .officeh-Image3 {
    padding-top: 12px;
    padding-right: 13px;
    clear: both; }
  .page-1551 .officeh-Image3 img {
    border-radius: 30px; }
  .page-1551 button#join-Session {
    margin-top: 14px;
    margin-bottom: 12px; }
  .page-1551 .officeh-Info {
    padding: 10px 0;
    font-weight: 600;
    font-size: 1.5rem; }
  .page-1551 .t-Dialog-body {
    padding: 0; }
  .page-1551 .sendMailDiv {
    padding-top: 18px; }
  .page-1551 .sendMail {
    background-color: dodgerblue;
    color: white;
    padding: 9px; }
  .page-1551 .col-12 > .rel-col .col-2 {
    width: 9.667%; }
  .page-1551 td.t-Report-cell {
    padding-left: 0 !important; }
  .page-1551 .boldText {
    font-weight: 700; }
  .page-1551 .ate-Hero {
    background-image: url("images/developer-bg.jpg");
    padding: 24px 0; }
  .page-1551 div#feedback {
    padding-top: 7px;
    margin-top: 13px;
    margin-bottom: 13px; }
  .page-1551 #embed-Vid {
    padding-top: 7px;
    margin-top: 13px;
    margin-bottom: 13px; }
  .page-1551 #ask-Aquestion {
    padding-bottom: 16px; }
  .page-1551 ol,
  .page-1551 ul {
    margin: 0.2rem 2.2rem; }
  .page-1551 .officeh-Body2 li {
    font-size: 18px; }
  .page-1551 .officeh-Body {
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 1.5rem; }
  .page-1551 .anchor-Text {
    font-size: 1.2rem; }
  .page-1551 .todays-Sesh {
    padding-bottom: 12px; }
  .page-1551 .top-Pad {
    padding-top: 19px; }
  .page-1551 .t-Form-inputContainer,
  .page-1551 .t-Form-labelContainer {
    padding-top: 0; }
  .page-1551 .col-12 > .rel-col .col-10 {
    top: 4px;
    left: -6px; }

/* Shame
   ========================================================================== */
/* APEX Overrides */
@media only screen and (max-width: 640px) {
  .t-ContentBlock--h2 .t-ContentBlock-title {
    line-height: 3.6rem; } }

.t-Tabs-item.is-active .t-Tabs-label {
  font-weight: 700; }

.t-Form-labelContainer--hiddenLabel {
  padding-left: 0 !important; }

@media only screen and (max-width: 640px) {
  .hide-label-xxs .t-Icon {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .hide-label-xxs .t-Button-label {
    display: none; } }

.t-ContentBlock--lightBG .t-ContentBlock-body {
  border-radius: 6px; }

.t-Region:not(.t-Region--noUI) {
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden; }

.t-Region-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px; }

/* Item Radio
   ========================================================================== */
body div[tabindex].checkbox_group:focus {
  outline: none !important; }

.apex-button-group input:checked + label,
.t-Form-fieldContainer--radioButtonGroup .apex-item-group--rc input:checked + label,
.checkbox_group input:checked + label:before,
.radio_group input:checked + label:before {
  color: #fff;
  background-color: #6A9D67;
  border-color: #6A9D67; }

.checkbox_group input:focus:checked + label:before,
.radio_group input:focus:checked + label:before {
  background-color: #6A9D67;
  border-color: #6A9D67; }

.checkbox_group input:focus + label:before,
.radio_group input:focus + label:before {
  border-color: #6A9D67; }

.apex-button-group input:active + label,
.apex-button-group input:focus:not(:checked) + label,
.t-Form-fieldContainer--radioButtonGroup .apex-item-group--rc input:active + label,
.t-Form-fieldContainer--radioButtonGroup .apex-item-group--rc input:focus:not(:checked) + label {
  box-shadow: 0 0 0 1px #6A9D67 inset !important; }

/* Item Radio Group
     ========================================================================== */
.t-Form-fieldContainer--radioButtonGroup .apex-item-grid {
  background-color: transparent;
  border-radius: 0; }

.t-Form-fieldContainer--radioButtonGroup .apex-item-group--rc input + label, .t-Form-fieldContainer--radioButtonGroup .apex-item-group--rc input + label:hover {
  color: #707070;
  background-color: transparent;
  border: none;
  border-radius: 2px !important;
  box-shadow: none; }

.t-Form-fieldContainer--radioButtonGroup .apex-item-group--rc input:checked + label {
  color: #AE562C !important;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.125), 0 2px 4px -2px rgba(0, 0, 0, 0.075); }

.t-Form-fieldContainer--radioButtonGroup .apex-item-group--rc input:checked:focus + label {
  box-shadow: inset 0 0 0 1px #6A9D67, 0 2px 4px -2px rgba(0, 0, 0, 0.075) !important; }

/* Checkboxes
   ========================================================================== */
@media only screen and (max-width: 480px) {
  .apex-item-group--rc .apex-item-option:not(:only-child) {
    width: 50%; } }

hr {
  border: 0;
  background: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 24px 0; }

.apex-ui-selector-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.apex-ui-selector {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  vertical-align: top; }
  .apex-ui-selector:before {
    content: '';
    display: block;
    transition: background-color .15s ease, box-shadow .15s ease, -webkit-transform .125s ease;
    transition: transform .125s ease, background-color .15s ease, box-shadow .15s ease;
    transition: transform .125s ease, background-color .15s ease, box-shadow .15s ease, -webkit-transform .125s ease;
    width: 16px;
    height: 16px; }
  .apex-ui-selector:after {
    content: '';
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: opacity 0.1 0.5s ease, -webkit-transform 0.2s ease;
    transition: opacity 0.1 0.5s ease, transform 0.2s ease;
    transition: opacity 0.1 0.5s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.apex-ui-selector-input[type=checkbox] + .apex-ui-selector:before {
  border-radius: 2px; }

.apex-ui-selector-input[type=checkbox] + .apex-ui-selector:after {
  font-family: apex-5-icon-font !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e007';
  font-size: 1.4rem;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center; }

.apex-ui-selector-input[type=radio] + .apex-ui-selector:before, .apex-ui-selector-input[type=radio] + .apex-ui-selector:after {
  border-radius: 100%; }

.apex-ui-selector-input[type=radio] + .apex-ui-selector:after {
  width: 6px;
  height: 6px;
  margin: 5px; }

.apex-ui-selector:active:before {
  -webkit-transform: scale(0.75);
  transform: scale(0.75); }

.apex-ui-selector-input:checked + .apex-ui-selector:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1); }

.apex-ui-selector:before {
  box-shadow: inset #a0a0a0 0 0 0 1px;
  background-color: white; }

.apex-ui-selector-input[type=checkbox] + .apex-ui-selector:after {
  color: #fff; }

.apex-ui-selector-input[type=radio] + .apex-ui-selector:after {
  background-color: #fff; }

.apex-ui-selector:hover:before {
  box-shadow: inset rgba(0, 0, 0, 0.5) 0 0 0 1px; }

.apex-ui-selector-input:checked + .apex-ui-selector:before {
  background-color: #6A9D67;
  box-shadow: none; }

.apex-ui-selector-input:checked + .apex-ui-selector:hover:before {
  background-color: #5f8f5c; }

.apex-ui-selector-input:focus + .apex-ui-selector:before {
  box-shadow: #6A9D67 0 0 0 1px, inset #fff 0 0 0 1px; }

.t-Body-actions,
.t-Body-content,
.t-Body-main,
.t-Body-nav,
.t-Body-side,
.t-Body-title,
.t-Button.t-Button--header,
.t-Header,
.a-Button,
.t-Button,
.ui-button,
a.t-Footer-topButton {
  transition: margin .1s ease, padding .1s ease, background-color .1s ease, color .1s ease, opacity .1s ease; }

/* Hero Region
   ========================================================================== */
.t-HeroRegion-title {
  font-size: 2.8rem; }

.t-HeroRegion-col.t-HeroRegion-col--right {
  flex-grow: 0; }

@media only screen and (max-width: 640px) {
  .t-HeroRegion-icon.t-Icon {
    font-size: 16px !important; } }

/* Responsive Utility to display with AEX Grid
   ========================================================================== */
.visible-wGrid {
  display: none; }
  @media only screen and (max-width: 640px) {
    .visible-wGrid {
      display: block; } }

.apex-item-textarea {
  min-width: 100%; }

/* Tabs */
.t-Tabs--fillLabels .t-Tabs-item:first-child .t-Tabs-link,
.t-Tabs--fillLabels .t-Tabs-item:last-child .t-Tabs-link {
  border-radius: 0; }

/* Links List Styles
   ========================================================================== */
.t-LinksList-label {
  padding: 12px 16px; }

.t-LinksList-icon {
  padding: 12px 0 12px 16px;
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.45); }

.t-LinksList-icon .fa {
  font-size: 16px;
  line-height: 16px;
  vertical-align: top;
  height: 16px;
  width: 16px;
  margin: 2px 0;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg); }

.t-LinksList-item.is-current > a {
  background-color: rgba(0, 0, 0, 0.025); }
  .t-LinksList-item.is-current > a .fa {
    color: rgba(16, 15, 14, 0.8); }

.at-User-photo {
  position: relative;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  text-align: center;
  background-color: #fff;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  align-self: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 44px;
  width: 44px;
  height: 44px; }
  .at-User-photo:not(.has-photo)[data-initials] {
    background-image: none !important; }
    .at-User-photo:not(.has-photo)[data-initials]:before {
      content: attr(data-initials); }

.t-Alert--wizard,
.t-Alert--horizontal {
  overflow: hidden; }

a.t-MediaList-itemWrap {
  color: #AE562C; }
  a.t-MediaList-itemWrap:hover {
    color: #AE562C; }

.a-IRR-sortWidget-row:focus,
.t-LinksList-link:focus,
a.t-MediaList-itemWrap:focus {
  box-shadow: 0 0 0 1px #6A9D67 inset; }

body div.a-Menu[tabindex]:focus,
.a-Menu .a-Menu-inner .a-Menu-label {
  outline: none !important; }

.nodatafound {
  text-align: center;
  color: rgba(16, 15, 14, 0.6);
  font-size: 15px;
  line-height: 16px;
  padding: 15px; }

p.report-Subtext {
  color: rgba(16, 15, 14, 0.6);
  font-size: 12px;
  margin-left: 13px; }

.t-Form-fieldContainer--floatingLabel.is-required:before {
  border-width: 4px;
  border-color: #C74634 transparent transparent #C74634;
  border-top-left-radius: 4px; }

.t-Form-fieldContainer.is-required .t-Form-label:after,
.t-Form-fieldContainer.is-required .t-Form-label:before {
  color: #C74634; }

.t-Button--navBar .a-Icon.icon-down-arrow:before {
  content: "\e0c2"; }

/* Previous AskTom style overrides */
.t-Footer {
  left: 0;
  right: 0;
  background-color: #153A4B;
  color: #91ACBD; }

.t-Footer a {
  color: #FFF; }

.t-Footer-apex {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 12px; }

.t-Footer-topButton {
  background-color: #91ACBD;
  color: #FFF; }

.t-Footer > a:first-child {
  display: none; }

.ate-Footer {
  display: table;
  width: 100%;
  table-layout: auto;
  border-spacing: 0; }

@media only screen and (max-width: 768px) {
  .ate-Footer {
    display: block;
    width: auto; } }

.ate-Footer-tagline,
.ate-Footer-body,
.ate-Footer-social {
  display: table-cell; }

.ate-Footer-tagline {
  width: 10%;
  padding-left: 12px;
  padding-right: 12px;
  vertical-align: middle; }

.ate-Footer-body {
  color: #91ACBD; }

.ate-Footer-body p {
  font-size: 1.1rem;
  margin: 0 12px 8px 12px;
  line-height: 1.6rem; }

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .ate-Footer-body p {
    margin: 0 8px 0 0; } }

@media only screen and (max-width: 768px) {
  .ate-Footer-body p {
    margin: 0 8px 0 0; } }

.ate-Footer-social {
  width: 10%;
  text-align: right;
  white-space: nowrap;
  padding-right: 12px; }

.ate-Footer-links {
  list-style: none;
  margin: 0 0 8px 0;
  padding: 0; }

.ate-Footer-links li {
  font-size: 1.1rem;
  display: inline-block;
  margin: 0 12px; }

@media only screen and (max-width: 768px) {
  .ate-Footer-links li {
    margin: 0 8px 0 0; } }

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .ate-Footer-links li {
    margin: 0 8px 0 0; } }

.ate-Footer-social .ate-Footer-links li {
  margin: 0; }

.ate-Footer-social .ate-Footer-links .fa {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 1.4rem;
  border-radius: 2px;
  color: #FFF;
  background-color: #91ACBD; }

.ate-Footer-social .ate-Footer-links .fa-rss:hover {
  background-color: #FA6800; }

.ate-Footer-social .ate-Footer-links .fa-youtube-play:hover {
  background-color: #C11F15; }

.ate-Footer-social .ate-Footer-links .fa-google-plus:hover {
  background-color: #DF4A32; }

.ate-Footer-social .ate-Footer-links .fa-twitter:hover {
  background-color: #52A2DB; }

.ate-Footer-social .ate-Footer-links .fa-linkedin:hover {
  background-color: #007AB8; }

.ate-Footer-social .ate-Footer-links .fa-facebook:hover {
  background-color: #39579A; }

@media only screen and (max-width: 768px) {
  .ate-Footer .ate-Footer-tagline,
  .ate-Footer .ate-Footer-body,
  .ate-Footer .ate-Footer-social {
    display: block;
    width: auto;
    padding: 8px 12px;
    text-align: left; }
  .ate-Footer .ate-Footer-social .ate-Footer-links .fa {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 2.0rem; } }

.oracle-tagline {
  width: 210px;
  height: 30px;
  display: inline-block;
  vertical-align: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%; }

@media only screen and (max-width: 640px) {
  .t-Footer {
    position: static; } }

.t-Header {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1); }

.t-Header .a-MenuBar.a-MenuBar--overflow,
.ate-Footer,
.t-Header-branding {
  /*max-width: 1280px;*/
  margin: 0 auto;
  float: none;
  outline: none; }

.t-BreadcrumbRegion {
  max-width: 1280px;
  margin: 0 auto; }

@media only screen and (max-height: 640px) {
  .t-Footer {
    position: static; } }

/* New Homepage and Hero */
.ate-Hero {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 64px 0; }

.ate-Hero-wrap {
  max-width: 1280px;
  margin: 0 auto; }

.ate-Hero-block {
  margin: 16px auto;
  padding: 48px;
  background: rgba(255, 255, 255, 0.9);
  max-width: 1024px;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); }

.ate-Hero-body {
  display: flex;
  align-items: center;
  margin-bottom: 24px; }

.ate-Hero-logo {
  display: block;
  width: 128px;
  height: 128px;
  margin-right: 24px;
  flex-shrink: 0; }

.ate-Hero-heading {
  flex-grow: 1; }

.ate-Hero-heading h1 {
  font-size: 36px;
  font-weight: 500;
  color: #000;
  margin: 0; }

.ate-Hero-heading p {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.55);
  margin: 0; }

.ate-Hero-block .t-Form-inputContainer {
  padding: 0; }

.ate-Hero-block .t-Form-fieldContainer input#P1000_SEARCH,
.ate-Hero-block .t-Form-fieldContainer input#P1000_SEARCH2 {
  padding: 20px;
  height: 64px;
  background: #FFF;
  font-size: 24px;
  line-height: 24px; }

.ate-Hero-block .t-Form-fieldContainer .apex-item-icon {
  padding: 20px 12px;
  margin-left: -48px;
  left: 48px;
  font-size: 24px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.45); }

.ate-Page--home .t-Body-title {
  border-width: 0; }

.t-ContentBlock-title {
  font-weight: 500;
  color: #000; }

.t-ContentBlock-title .t-Button {
  float: right;
  margin: 8px 0; }

p.content-intro {
  font-size: 17px;
  margin-bottom: 24px;
  margin-top: -8px;
  color: rgba(0, 0, 0, 0.55); }

/* Home Page
   ========================================================================== */
/*
.ate-Page--home .t-Body-contentInner,
.ate-Page--home .t-Header .a-MenuBar.a-MenuBar--overflow,
.ate-Page--home .ate-Footer,
.t-Header-branding,
.ate-Page--home .t-Footer-apex,
.t-Header .a-MenuBar.a-MenuBar--overflow,
.t-Body-contentInner,
.apex-rds-container,
.t-BreadcrumbRegion {
  max-width: 1024px;
}
*/
.t-Footer-srMode {
  display: none; }

/* New theme styles */
.apex-rds-container {
  margin: 0 auto !important; }

@media only screen and (max-width: 640px) {
  .ate-Hero-body {
    flex-direction: column;
    text-align: center; }
  .ate-Hero-heading h1 {
    font-size: 24px; }
  .ate-Hero-heading p {
    font-size: 16px; }
  .ate-Hero-logo {
    margin-right: 0;
    margin-bottom: 24px; }
  .ate-Hero-block {
    margin: 16px; } }

/*
.t-Header-nav-list{
padding-bottom: 42px;}

.a-MenuBar-item:hover{
border-bottom: 2px solid #f29111;
}


.a-Menu--current{
border-bottom: 2px solid #f29111 !important;
}
*/
/* Orange Header Block */
.ate-TitleBar {
  max-width: 990px;
  margin: 5px auto;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 12px;
  border-radius: 2px; }

.ate-TitleBar h1 {
  font-weight: 500;
  margin: 0; }

.ate-TitleBar-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.ate-TitleBar-body {
  flex-grow: 1; }

.ate-TitleBar-buttons {
  padding-right: 12px; }

/* Header
   ========================================================================== */
.t-Header {
  background-color: #403C38;
  min-height: 64px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0px;
  padding-bottom: 10px;
  min-width: 320px;
  left: auto;
  right: auto;
  width: 100%; }

.t-PageBody--topBar .t-Header {
  box-shadow: none; }

.t-Header-branding {
  overflow: visible;
  background-color: transparent; }

.t-Header-controls {
  display: none; }

.t-Header-logo {
  margin-right: auto;
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: visible;
  line-height: 16px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 700; }

.t-Header-logo img {
  max-height: 64px; }

.t-Header-logo-link .apex-logo-text {
  display: flex !important; }

@media only screen and (max-width: 480px) {
  .t-Header-logo img {
    max-height: 48px; } }

.t-Header-logo-link {
  padding: 10px;
  margin: -10px;
  white-space: nowrap;
  border-radius: 4px; }

.t-Header-logo-link:active:focus,
.t-Header-logo-link:focus {
  box-shadow: none; }

.t-Header-logo-link > span {
  display: flex;
  align-items: center; }

.t-Header-logo-link > span img {
  position: relative;
  z-index: -1; }

.t-Header-logo-link > span img + span {
  margin-left: 12px; }

.t-Header-logo-link > span:before {
  content: '';
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI0M3NDYzNCIgZD0iTTI0LjYyOCAwQzMwLjkyMyAwIDM2IDUuMzc0IDM2IDEyLjAwMyAzNiAxOC42MzIgMzAuOTIzIDI0IDI0LjYyOCAyNGwtMTMuMjQtLjAwNkM1LjA5OSAyMy45OTQgMCAxOC42MzIgMCAxMi4wMDNTNS4wOTkgMCAxMS4zODkgMGgxMy4yMzl6bS0uMzAyIDQuMDI1SDExLjY4MmMtNC4wNyAwLTcuMzY5IDMuNTc0LTcuMzY5IDcuOTk3IDAgNC40MTggMy4zIDguMDAzIDcuMzcgOC4wMDNoMTIuNjQzYzQuMDgyIDAgNy4zNzctMy41ODUgNy4zNzctOC4wMDMgMC00LjQyMy0zLjI5NS03Ljk5Ny03LjM3Ny03Ljk5N3oiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center center;
  width: 36px;
  height: 24px;
  display: block;
  margin-right: 12px;
  position: relative;
  z-index: -1; }

@media only screen and (max-width: 480px) {
  .t-Header-logo-link > span span:not(:only-child) {
    display: none; } }

.t-Header-logo-link span {
  color: #FCFBFA;
  text-transform: uppercase; }

.t-Header-navBar {
  margin-right: 0;
  margin-left: auto; }

@media only screen and (max-width: 992px) {
  .t-Header-navBar {
    margin-left: 0; } }

.t-Header-branding,
.t-Header-logo-oracle:before {
  height: auto; }

/* Navigation Bar Alignment
       ========================================================================== */
.t-NavigationBar {
  display: flex;
  align-items: center; }

.t-Button.t-Button--navBar {
  display: flex;
  height: 44px;
  min-width: 44px;
  border-radius: 4px;
  padding: 12px;
  align-items: center;
  justify-content: center; }

.t-Button.t-Button--navBar:before,
.t-Button.t-Button--navBar:after {
  content: none; }

.t-Button.t-Button--navBar .t-Icon,
.t-Button.t-Button--navBar .fa {
  width: 20px;
  height: 20px;
  line-height: 20px; }

.t-Button.t-Button--navBar .t-Icon:before,
.t-Button.t-Button--navBar .fa:before {
  font-size: 20px; }

body .t-Button.t-Button--navBar,
body .t-Header .t-Button--header {
  background-color: transparent;
  box-shadow: none;
  color: #FCFBFA; }

body .t-Button.t-Button--navBar:hover,
body .t-Button.t-Button--navBar:focus,
body .t-Header .t-Button--header:hover,
body .t-Header .t-Button--header:focus {
  background-color: rgba(49, 45, 42, 0.05);
  box-shadow: none;
  outline-color: #FCFBFA !important; }

body .t-Button.t-Button--navBar:active,
body .t-Header .t-Button--header:active {
  background-color: rgba(49, 45, 42, 0.08);
  box-shadow: none; }

body .t-Button.t-Button--navBar.is-active,
body .t-Header .t-Button--header.is-active {
  color: #FCFBFA;
  background-color: transparent; }

.t-Button--navBar .t-Button-badge {
  font-weight: 500;
  color: #fff;
  background-color: #389C91;
  align-self: center;
  font-size: 11px;
  line-height: 18px;
  min-width: 18px; }

/* Search Icon
       ========================================================================== */
@media only screen and (min-width: 993px) {
  .header-search-item {
    display: none; } }

@media only screen and (max-width: 992px) {
  .header-search-item {
    display: inline-block; } }

/* Feedback Icon
       ========================================================================== */
@media only screen and (max-width: 480px) {
  .header-feedback-item {
    display: none; } }

/* User Icon
       ========================================================================== */
.js-headerUserMenu .t-Button--navBar .dg-User {
  margin-top: -12px;
  margin-left: -12px;
  margin-bottom: -12px; }

.t-Button--navBar .a-Icon.icon-down-arrow {
  opacity: .6;
  margin-left: 6px; }

@media only screen and (max-width: 480px) {
  .t-Button--navBar .a-Icon.icon-down-arrow {
    display: none; } }

/* Header Domain Selector
       ========================================================================== */
.js-domainSelector {
  display: none; }

.dg-Header-domainSelector.t-Button {
  margin-right: 0;
  margin-left: 44px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  flex-shrink: 0; }

.dg-Header-domainSelector {
  background-color: rgba(0, 0, 0, 0.05); }

.dg-Header-domainSelector .t-Icon {
  display: block;
  width: 24px;
  height: 24px;
  background-size: 100%;
  margin-left: 2px; }

@media only screen and (max-width: 768px) {
  .dg-Header-domainSelector .t-Icon {
    margin: 0; } }

.dg-Header-domainSelector .t-Button-label {
  margin-right: 4px;
  margin-left: 6px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis; }

@media only screen and (max-width: 768px) {
  .dg-Header-domainSelector .t-Button-label {
    display: none; } }

.dg-Header-domainSelector .t-Button-badge {
  display: inline-block;
  font-weight: normal;
  background: none;
  color: inherit;
  font-size: 14px;
  line-height: 1;
  padding: 0;
  margin-left: 4px;
  margin-right: 4px; }

@media only screen and (max-width: 768px) {
  .dg-Header-domainSelector .t-Button-badge {
    display: none; } }

.dg-Header-domainSelector .t-Button-badge:after {
  font-family: 'Font APEX Small' !important;
  content: "\f0dd"; }

/* Header Search Input
       ========================================================================== */
.dg-Header-search {
  position: relative;
  display: block;
  margin: 2px 44px;
  transition: margin .2s ease;
  align-self: center;
  flex-grow: 1; }

@media only screen and (max-width: 992px) {
  .dg-Header-search {
    margin-right: 8px;
    margin-left: 8px; } }

@media only screen and (max-width: 992px) {
  .t-Header .dg-Header-search {
    display: none !important; }
  .dg-Search-responsive .dg-Header-search {
    display: block; } }

input.dg-Header-searchInput {
  display: block;
  box-sizing: border-box;
  padding: 10px 10px 10px 46px;
  min-width: 12.0rem;
  width: 100%;
  height: 4rem;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  background: #56504B;
  color: #FCFBFA;
  background-clip: padding-box;
  border: 0;
  border-radius: 40px;
  transition: .2s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input.dg-Header-searchInput::-webkit-input-placeholder {
  line-height: normal; }

input.dg-Header-searchInput:hover,
input.dg-Header-searchInput:focus,
input.dg-Header-searchInput:active {
  outline: none; }

input.dg-Header-searchInput:focus,
input.dg-Header-searchInput:active {
  color: #1A1816;
  background-color: #FCFBFA; }

.dg-Header-searchLabel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin: 10px 16px;
  line-height: 20px;
  pointer-events: none;
  color: #FCFBFA;
  transition: color .2s ease; }

.dg-Header-search:focus-within .dg-Header-searchLabel {
  color: #1A1816; }

.dg-Header-searchLabel .fa {
  font-size: 20px; }

/* Responsive Search Container
       ========================================================================== */
.dg-Search-responsive {
  position: fixed;
  top: 0;
  left: 0;
  padding: 2.4rem;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: opacity .2s ease; }

.dg-Search-responsive.is-hidden {
  position: static;
  visibility: visible;
  opacity: 0; }

.dg-Search-responsive.is-visible {
  z-index: 10000;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.7); }

.dg-Search-responsive .dg-Header-searchLabel {
  top: 24px;
  left: 24px;
  margin: 1.2rem;
  transition: .2s ease;
  color: #1A1816; }

.dg-Search-responsive .dg-Header-searchLabel .fa {
  width: 2.4rem;
  height: 2.4rem;
  font-size: 2.4rem;
  line-height: 1; }

.dg-Search-responsive input.dg-Header-searchInput {
  padding: .8rem .8rem .8rem 4.4rem;
  max-width: 100%;
  height: 4.8rem;
  font-size: 1.8rem;
  transition: border .2s ease; }

@media (min-width: 769px) {
  .t-Header-nav {
    margin-top: 0px;
    margin-bottom: -10px;
    overflow: visible; } }

@media (min-width: 769px) {
  .t-Header-nav .t-NavTabs {
    overflow: visible;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
    background-color: transparent; } }

.t-Header-nav .t-NavTabs-item {
  border-color: transparent; }
/* ESF 7-DEC-2023
@media (min-width: 769px) {
  .t-Header-nav .t-NavTabs-link {
    padding: 8px 12px; } }
*/
@media (min-width: 769px) {
  .t-Header-nav .t-NavTabs-item:hover,
  .t-Header-nav .t-NavTabs-item.is-active {
    background-color: transparent; } }

@media (min-width: 769px) {
  .t-Header-nav .t-NavTabs-item.is-active {
    font-weight: 700;
    box-shadow: inset #6B8F63 0 -4px 0; } }

/* Footer
  ========================================================================== */
/* Footer
   ========================================================================== */
.t-Footer {
  position: relative;
  flex-direction: column;
  background-color: #312D2A;
  color: rgba(252, 251, 250, 0.6);
  border-top: 0;
  padding: 56px 20px 44px;
  text-align: center;
  font-size: 14px;
  line-height: 20px; }

.t-Footer a {
  color: #fcfbfa; }

.t-Footer a:hover,
.t-Footer a:focus {
  text-decoration: underline; }

.t-Footer p {
  margin-bottom: 40px; }

.t-Footer p:last-child {
  margin-bottom: 0; }

.t-Footer:before {
  background-image: url(../images/rw/footer-top-bg.png);
  background-size: auto 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  content: ''; }

.u-BuiltWithAPEX {
  display: inline; }

.u-BuiltWithAPEX .fa-heart {
  color: #C74634;
  font-size: 20px;
  margin: -2px 4px;
  vertical-align: text-top;
  display: inline-block; }

.u-BuiltWithAPEX:hover .fa-heart {
  color: #C74634; }

.t-Footer-body {
  margin: 0 auto;
  width: 100%; }

.t-Footer-apex {
  display: none; }

.t-Footer-top {
  margin-top: 40px; }

a.t-Footer-topButton {
  background-color: #FCFBFA;
  color: #FCFBFA;
  opacity: 1;
  padding: 10px;
  width: 44px;
  height: 44px;
  box-shadow: none !important; }

a.t-Footer-topButton:hover {
  opacity: .8; }

a.t-Footer-topButton .a-Icon {
  font-size: 24px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  color: #100F0E; }

a.t-Footer-topButton .a-Icon:before {
  font-size: inherit; }

.o-Footer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 16px;
  padding-right: 16px; }

.o-Footer:before,
.o-Footer:after {
  content: ' ';
  display: table; }

.o-Footer:after {
  clear: both; }

@media only screen and (max-width: 768px) {
  .o-Footer {
    display: flex;
    flex-direction: column;
    align-items: center; } }

.o-Footer-tagLine {
  order: 2;
  margin: 0; }

@media only screen and (min-width: 769px) {
  .o-Footer-tagLine {
    float: left; } }

.o-Footer-tagLine a {
  display: block;
  color: #fff;
  outline: none;
  transition: color .2s ease; }

.o-Footer-tagLine a:hover,
.o-Footer-tagLine a:focus {
  color: rgba(255, 255, 255, 0.7); }

.o-Footer-tagLine svg {
  display: block;
  fill: currentColor;
  width: 350px;
  max-width: 100%;
  height: auto; }

.o-Footer-socialLinks {
  margin: 0 auto 20px;
  text-align: center;
  order: 1; }

@media only screen and (min-width: 769px) {
  .o-Footer-socialLinks {
    margin: 0;
    float: right; } }

.o-Footer-socialLinks ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline;
  font-size: 0; }

.o-Footer-socialLinks ul li {
  display: inline-block;
  margin: 5px; }

@media only screen and (min-width: 769px) {
  .o-Footer-socialLinks ul li {
    margin: 8px; } }

a.o-Footer-socialLink {
  display: block;
  width: 29px;
  height: 28px;
  transition: background-color .1s ease, color .1s ease;
  background-color: #fff;
  color: #212120;
  border-radius: 2px;
  outline: none; }

@media only screen and (min-width: 769px) {
  a.o-Footer-socialLink {
    width: 34px;
    height: 33px; } }

a.o-Footer-socialLink:hover,
a.o-Footer-socialLink:focus {
  background-color: rgba(255, 255, 255, 0.7);
  color: #212120; }

a.o-Footer-socialLink svg {
  display: block;
  fill: currentColor;
  height: 100%;
  width: 100%; }

.o-Footer-links {
  margin: 0;
  margin-top: 20px;
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 12.5px;
  line-height: 15px;
  order: 3;
  color: inherit; }

@media only screen and (min-width: 769px) {
  .o-Footer-links {
    float: right;
    margin-top: 12px;
    clear: both; } }

.o-Footer-links li {
  font-size: inherit;
  display: inline; }

.o-Footer-links li:not(:last-child) {
  padding-right: 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.35);
  margin-right: 6px; }

.o-Footer-links li a {
  outline: none;
  transition: color .2s ease; }

.o-Footer-links li a:hover,
.o-Footer-links li a:focus {
  color: #fff; }

/*1/26/2021 Theme Roller CSS transfer */
.dg-ContentRowReport-itemContent .dg-ContentRowReport-itemDescription {
  color: #100f0e; }

/* Cookie link styles*/
div#teconsent {
  display: inline; }

/* 20.2 modifications */
.t-NavTabs-item {
    font-size: 14px;
    font-weight: 550;
    color: rgba(255, 255, 255, 0.6);
}

.t-NavTabs-item.is-active, .t-NavTabs-item.is-active:hover {
  color: #fcfbfa; }

/* Cards alterations */
.a-CardView-mainContent {
  line-height: 1.5; }

.video-teacher {
  font-weight: 700;
  font-size: 14px; }

.video-info {
  font-size: 12px;
  line-height: 1.5; }

.a-CardView-header {
  padding-bottom: 8px; }

.a-CardView-body {
  justify-content: flex-end; }

.a-CardView-title {
  font-size: 16px;
  line-height: 1.35;
  font-weight: 700; }

/*Sidebar */
button.a-FS-addChart.js-addChart.a-Button.a-Button--noLabel.a-Button--withIcon {
  background-color: transparent;
  box-shadow: none; }

/*# sourceMappingURL=asktom.css.map */
